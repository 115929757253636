import React from "react";
import map from "lodash/map";
import padStart from "lodash/padStart";
import reverse from "lodash/reverse";
import { AccountBackArrow } from "components/AccountBackArrow";
import { AccountSidebar } from "components/AccountSidebar";
import { DateTime } from "luxon";
import { Environment } from "@trunkery/internal/lib/environment";
import { Footer } from "components/Footer";
import { FooterMenu } from "components/FooterMenu";
import { FullAddressFragment, GetOrderFulfillmentsQuery, GetOrderQuery } from "gql/types";
import { Money } from "components/Money";
import { RequestResponseMulti } from "@lana-commerce/core/request";
import { RouteComponentProps } from "@reach/router";
import { VariantAnchor } from "components/VariantAnchor";
import { convertImageURL } from "@lana-commerce/core/convertImageURL";
import { environmentFromSiteData } from "utils/environmentFromSiteData";
import { globalAuthState } from "utils/globalAuthState";
import { globalDataCache } from "utils/globalDataCache";
import { imageAltProps } from "@lana-commerce/core/imageAltProps";
import { niceCancelReasonText } from "utils/niceCancelReasonText";
import { niceFinancialText } from "utils/niceFinancialText";
import { niceFulfillmentText } from "utils/niceFulfillmentText";
import { nonNull } from "@lana-commerce/core/nonNull";
import { respItems } from "utils/respItems";
import { useForceUpdate } from "utils/useForceUpdate";
import { usePrefetchLocation } from "components/PrefetchRouter";
import { useSiteData } from "utils/useSiteData";
import { variantImage } from "@lana-commerce/core/variantImage";
import { variantOptions } from "@lana-commerce/core/variantOptions";
import { variantTitle } from "@lana-commerce/core/variantTitle";
import { variantURL } from "@lana-commerce/core/variantURL";

import { T } from "./Order.tlocale";

function addressText(a: FullAddressFragment) {
  return (
    <span>
      {a.name}
      <br />
      {a.address1}
      <br />
      {a.city}, {a.province ? a.province.code : ""} {a.zip}
      <br />
      {a.country ? a.country.name : ""}
    </span>
  );
}

//==========================================================================================
//==========================================================================================
//==========================================================================================

interface OrderLineItemProps {
  item: NonNullable<GetOrderQuery["storefrontOrders"]>[0]["line_items"][0];
  customItem: NonNullable<NonNullable<GetOrderQuery["storefrontOrders"]>[0]["custom_items"]>[0] | undefined;
  detail: NonNullable<NonNullable<GetOrderQuery["storefrontOrders"]>[0]["line_items"][0]["detail"]>;
  currency: string;
  env: Environment;
}

class OrderLineItem extends React.Component<OrderLineItemProps> {
  render() {
    const { item, customItem, detail, currency, env } = this.props;
    const image = variantImage(item.variant);
    return (
      <tr>
        <td>
          <div className="order-item">
            <div className="order-item__image">
              <VariantAnchor variant={item.variant}>
                <img
                  src={convertImageURL(env.cdn, image?.public_url, { size: "102x102" })}
                  width={102}
                  height={102}
                  {...imageAltProps(image)}
                />
              </VariantAnchor>
            </div>
            <div className="order-item__content">
              <div className="order-item__title">
                <VariantAnchor className="default-blue-link" variant={item.variant}>
                  {variantTitle(item.variant) || customItem?.title}
                </VariantAnchor>
              </div>
              <div className="order-item__subtitle">{variantOptions(item.variant)}</div>
              <div className="order-item__sku">
                {T("SKU: {sku}", { sku: item.variant ? item.variant.sku : T("<none>") })}
              </div>
              {env.reviewsEnabled && item.variant && item.variant.product ? (
                <div className="order-item__review-link">
                  <a className="default-link" href={`${variantURL(env.baseURL, item.variant)}#reviews`}>
                    {T("Write review")}
                  </a>
                </div>
              ) : null}
            </div>
          </div>
        </td>
        <td>
          <div className="mtop15">{item.quantity}</div>
        </td>
        <td>
          <div className="mtop15">
            <Money env={env} currency={currency} value={detail.price} />
          </div>
        </td>
      </tr>
    );
  }
}

class OrderLineItemMobile extends React.Component<OrderLineItemProps> {
  render() {
    const { item, detail, currency, env } = this.props;
    const image = variantImage(item.variant);
    return (
      <div className="item-list__item">
        <div className="order-item">
          <div className="order-item__quantity">{item.quantity}</div>
          <div className="order-item__image">
            <img
              src={convertImageURL(env.cdn, image?.public_url, { size: "102x102" })}
              width={102}
              height={102}
              {...imageAltProps(image)}
            />
          </div>
          <div className="order-item__content">
            <div className="order-item__title">{variantTitle(item.variant)}</div>
            <div className="order-item__subtitle">{variantOptions(item.variant)}</div>
            <div className="order-item__sku">
              {T("SKU: {sku}", { sku: item.variant ? item.variant.sku : T("<none>") })}
            </div>
            <div className="order-item__price">
              <Money env={env} currency={currency} value={detail.price} />
            </div>
            <div className="order-item__review-link">
              <a className="default-link" href="#reviews">
                {T("Write review")}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

//==========================================================================================
//==========================================================================================
//==========================================================================================

type DataType = RequestResponseMulti<GetOrderQuery & GetOrderFulfillmentsQuery>;

interface OrderPageProps {
  data: DataType;
  env: Environment;
}

export class OrderPage extends React.Component<OrderPageProps> {
  getOrder() {
    return respItems(this.props.data)?.storefrontOrders?.[0] || undefined;
  }

  getOrderFulfillments() {
    return respItems(this.props.data)?.storefrontOrderFulfillments || [];
  }

  render() {
    const { env } = this.props;
    const o = this.getOrder();
    if (!o) return null;
    if (!o.display) return null;

    const fulfillments = this.getOrderFulfillments();
    const fulfillmentsEls = map(fulfillments, (f, idx) => (
      <div className="margin-block">
        <div className="order-page-label">
          {T("Shipment #{number}", {
            number: (
              <>
                {o.number}-{padStart(`${idx + 1}`, 2, "0")}
              </>
            ),
          })}
        </div>
        <div className="default-font desktop-only">
          {f.tracking_company} -{" "}
          <a className="default-link" href={f.tracking_url}>
            {f.tracking_number}
          </a>
        </div>
        <div className="default-font tacenter mobile-only">
          <div>{f.tracking_company}</div>
          <div>
            <a className="default-link" href={f.tracking_url}>
              {f.tracking_number}
            </a>
          </div>
        </div>
      </div>
    ));
    return (
      <div className="account-layout">
        <AccountSidebar active="orders" />
        <div className="account-layout__content">
          <div className="account-page-header">
            <AccountBackArrow />
            <div className="account-page-header__title">{T("Order #{number}", { number: o.number })}</div>
          </div>

          <div className="margin-block">
            <div className="account-page-centered fs15">
              {DateTime.fromISO(o.paid_at || "").toLocaleString(DateTime.DATETIME_FULL)}
            </div>
          </div>

          {reverse(fulfillmentsEls)}

          <div className="fat-separator mobile-only" />

          <div className="form-grid-row desktop-only">
            <div className="form-grid-col-6">
              <div className="margin-block">
                <div className="order-page-label">{T("Billing Address")}</div>
                <div className="default-font">{o.billing_address ? addressText(o.billing_address) : null}</div>
              </div>
            </div>
            <div className="form-grid-col-6">
              <div className="margin-block">
                <div className="order-page-label">{T("Shipping Address")}</div>
                <div className="default-font">{o.shipping_address ? addressText(o.shipping_address) : null}</div>
              </div>
            </div>
          </div>

          <div className="margin-block-small">
            <div className="order-page-label order-page-label--inline">{T("Fulfillment Status")}</div>
            <span className="default-font">{niceFulfillmentText(o.fulfillment_status)}</span>
          </div>

          <div className="margin-block">
            <div className="order-page-label order-page-label--inline">{T("Payment Status")}</div>
            <span className="default-font">{niceFinancialText(o.financial_status)}</span>
          </div>

          {o.canceled_at ? (
            <div className="default-font margin-block-small">
              {T("Order Cancelled on {date}", {
                date: DateTime.fromISO(o.canceled_at).toLocaleString(DateTime.DATE_MED),
              })}
            </div>
          ) : null}

          {o.cancel_reason ? (
            <div className="default-font margin-block">
              {T("Reason: {reason}", { reason: niceCancelReasonText(o.cancel_reason) })}
            </div>
          ) : null}

          <div className="fat-separator mobile-only" />

          <div>
            <table className="account-table account-table--valign-top">
              <thead>
                <tr>
                  <th>{T("Item")}</th>
                  <th>{T("Quantity")}</th>
                  <th>{T("Price")}</th>
                </tr>
              </thead>
              <tbody>
                {map(o.line_items, (li) => {
                  const ci = li.custom_item_index >= 0 ? o.custom_items?.[li.custom_item_index] : undefined;
                  return (
                    <OrderLineItem
                      env={env}
                      key={li.id}
                      item={li}
                      customItem={ci}
                      detail={nonNull(li.detail)}
                      currency={o.currency}
                    />
                  );
                })}
              </tbody>
            </table>

            <div className="item-list">
              {map(o.line_items, (li) => {
                const ci = li.custom_item_index >= 0 ? o.custom_items?.[li.custom_item_index] : undefined;
                return (
                  <OrderLineItemMobile
                    env={env}
                    key={li.id}
                    item={li}
                    customItem={ci}
                    detail={nonNull(li.detail)}
                    currency={o.currency}
                  />
                );
              })}
            </div>

            <div className="fat-light-separator desktop-only" />
            <div className="fat-separator mobile-only" />

            <div className="order-total margin-block-small">
              <div className="order-total__label">{T("Subtotal")}</div>
              <div className="order-total__value">
                <Money env={env} currency={o.currency} value={o.display.subtotal} />
              </div>
            </div>
            {o.display.shipping ? (
              <div className="order-total margin-block-small">
                <div className="order-total__label">{T("Shipping")}</div>
                <div className="order-total__value">
                  <Money env={env} currency={o.currency} value={o.display.shipping} />
                </div>
              </div>
            ) : null}
            {o.display.discount ? (
              <div className="order-total margin-block-small">
                <div className="order-total__label">{T("Discounts")}</div>
                <div className="order-total__value">
                  - <Money env={env} currency={o.currency} value={o.display.discount} />
                </div>
              </div>
            ) : null}
            {o.display.tax ? (
              <div className="order-total margin-block">
                <div className="order-total__label">{o.tax_inclusive_prices ? T("Tax (included)") : T("Tax")}</div>
                <div className="order-total__value">
                  <Money env={env} currency={o.currency} value={o.display.tax} />
                </div>
              </div>
            ) : null}
            <div className="order-grand-total">
              <div className="order-grand-total__label">{T("Total")}</div>
              <div className="order-grand-total__value">
                <Money env={env} currency={o.currency} value={o.display.total} />
              </div>
            </div>

            {o.display.customer_balance_spent > 0 ? (
              <>
                <div className="fat-light-separator desktop-only" />
                <div className="fat-separator mobile-only" />
                <div className="order-total margin-block">
                  <div className="order-total__label">{T("Customer Balance Spent")}</div>
                  <div className="order-total__value">
                    <Money env={env} currency={o.currency} value={o.display.customer_balance_spent} />
                  </div>
                </div>
              </>
            ) : null}

            <div className="fat-separator mobile-only" />

            <div className="form-grid-row mobile-only">
              <div className="form-grid-col-6">
                <div className="margin-block">
                  <div className="order-page-label">{T("Billing Address")}</div>
                  <div className="default-font tacenter">
                    {o.billing_address ? addressText(o.billing_address) : null}
                  </div>
                </div>
              </div>
              <div className="form-grid-col-6">
                <div className="margin-block">
                  <div className="order-page-label">{T("Shipping Address")}</div>
                  <div className="default-font tacenter">
                    {o.shipping_address ? addressText(o.shipping_address) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default (_props: RouteComponentProps) => {
  const siteData = useSiteData();
  const env = environmentFromSiteData(siteData);
  const location = usePrefetchLocation();
  const forceUpdate = useForceUpdate();
  const data = globalDataCache.accountOrderCache.get(
    env,
    globalAuthState.auth,
    location.pathname,
    location.key,
    forceUpdate
  );
  if (!data) return null;
  return (
    <div className="page-with-menu">
      <OrderPage data={data} env={env} />
      <div className="page-with-menu__content page-with-menu__content--no-padding page-with-menu__content--no-max-width">
        <FooterMenu />
        <Footer />
      </div>
    </div>
  );
};
