import * as React from "react";
import find from "lodash/find";
import getProvinces from "gql/operations/GetProvincesQuery";
import sortBy from "lodash/sortBy";
import { GetProvincesQuery } from "gql/types";
import { RouteData } from "@trunkery/internal/lib/vatureTypes";
import { lastValue } from "utils/lastValue";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { request } from "@lana-commerce/core/request";

type Province = NonNullable<GetProvincesQuery["provinces"]>[0];

interface ProvinceSelectGenericProps {
  countryCode: string;
  renderComponent: (provinces: Province[]) => JSX.Element;
  siteData: RouteData.SiteData;
}

@observer
export class ProvinceSelectGeneric extends React.Component<ProvinceSelectGenericProps> {
  @observable.ref private provinces: Province[] = [];

  private fetchProvinces = lastValue(async (countryCode: string) => {
    const { siteData } = this.props;
    if (!countryCode) return [];
    const resp = await request(getProvinces)(
      { shopID: siteData.shop.id, countryCode },
      { url: `${siteData.config.host}/storefront.json` }
    );
    if (resp.kind === "data") {
      return sortBy(resp.data, (d) => d.name);
    } else {
      return [];
    }
  });

  private doFetchProvinces(countryCode: string) {
    this.fetchProvinces(countryCode).then((result) => {
      if (result.kind === "canceled") {
        // do nothing
      } else if (result.kind === "valid") {
        this.provinces = result.value;
      }
    });
  }

  resolveProvince(value: string) {
    return find(this.provinces, (p) => p.code === value);
  }

  componentDidMount() {
    this.doFetchProvinces(this.props.countryCode);
  }

  componentWillReceiveProps(newProps: Readonly<ProvinceSelectGenericProps>) {
    if (newProps.countryCode !== this.props.countryCode) {
      this.doFetchProvinces(newProps.countryCode);
    }
  }

  render() {
    return this.props.renderComponent(this.provinces);
  }
}
