import IPAndUAInfo from "../fragments/IPAndUAInfo";
import InfoCountry from "../fragments/InfoCountry";
import InfoCountryDedupField from "../fragments/InfoCountryDedupField";
import InfoProvince from "../fragments/InfoProvince";
import type { GetGeoIPQueryMeta } from "../types";
export default (IPAndUAInfo +
  InfoCountry +
  InfoCountryDedupField +
  InfoProvince +
  "query GetGeoIP{analyticsGeoip{...IPAndUAInfo}}") as unknown as GetGeoIPQueryMeta;
