import React from "react";
import classnames from "classnames";
import { Link } from "gatsby";
import { environmentFromSiteData } from "utils/environmentFromSiteData";
import { paths } from "utils/paths";
import { useSiteData } from "utils/useSiteData";

import { T } from "./AccountSidebar.tlocale";

export type AccountSidebarMenuItem = "orders" | "addresses" | "payment-methods" | "store-credit" | "profile";

interface AccountSidebarProps {
  active: AccountSidebarMenuItem;
}

export const AccountSidebar = (props: AccountSidebarProps) => {
  const siteData = useSiteData();
  const env = environmentFromSiteData(siteData);
  const activeIf = (current: AccountSidebarMenuItem) => ({
    "account-layout__menu-item--active": current === props.active,
  });
  return (
    <div className="account-layout__menu">
      <Link to={paths.accountOrders} className={classnames("account-layout__menu-item", activeIf("orders"))}>
        {T("Orders")}
      </Link>
      <Link to={paths.accountAddresses} className={classnames("account-layout__menu-item", activeIf("addresses"))}>
        {T("Address Book")}
      </Link>
      {env.stripeCardTokenization ? (
        <Link
          to={paths.accountPaymentMethods}
          className={classnames("account-layout__menu-item", activeIf("payment-methods"))}
        >
          {T("Saved Cards")}
        </Link>
      ) : null}
      <Link to={paths.accountStoreCredit} className={classnames("account-layout__menu-item", activeIf("store-credit"))}>
        {T("Store Credit")}
      </Link>
      <Link to={paths.accountProfile} className={classnames("account-layout__menu-item", activeIf("profile"))}>
        {T("Profile")}
      </Link>
    </div>
  );
};
