import React from "react";
import classnames from "classnames";
import find from "lodash/find";
import map from "lodash/map";
import { Link } from "gatsby";
import { menuItemLink } from "utils/menuItemLink";
import { useSiteData } from "utils/useSiteData";

import { T } from "./FooterMenu.tlocale";

export const FooterMenu = () => {
  const { menus, categoryBriefs } = useSiteData();
  const menuItems = find(menus, (m) => m.handle === "footer")?.items || [];
  return (
    <div className="footer-menu">
      <div className="footer-menu__items">
        <div className="footer-menu__item footer-menu__item--header">
          <div className="footer-menu__item-header">{T("Follow us")}</div>
          <div className="footer-menu__item-icons">
            <a target="_blank" href="https://www.instagram.com/vatureofficial/">
              <img width="20" height="20" src={require("images/instagram-1.svg").default} />
            </a>
            <a target="_blank" href="https://www.facebook.com/vatureofficial/">
              <img width="15" height="24" src={require("images/facebook-2.svg").default} />
            </a>
          </div>
        </div>
        <div className="footer-menu__item footer-menu__item--email">
          <div className="footer-menu__item-email">
            <a href="mailto:hello@vature.com">hello@vature.com</a>
          </div>
        </div>
        {map(menuItems, (item, idx) => (
          <div
            key={item.id}
            className={classnames("footer-menu__item", {
              "footer-menu__item--1st-col": idx % 2 === 0,
              "footer-menu__item--2nd-col": idx % 2 !== 0,
            })}
          >
            <Link to={menuItemLink(item, categoryBriefs)}>{item.name}</Link>
          </div>
        ))}
      </div>
      <div className="footer-menu__subscribe-widget">
        <div className="subscribe-widget">
          <div className="subscribe-widget__header">{T("Signup")}</div>
          <div className="subscribe-widget__text">
            {T("Be the first to find out about new products and exclusive offers.")}
          </div>
          <div className="subscribe-widget__input-and-button">
            <input type="text" className="subscribe-widget__input" />
            <button className="subscribe-widget__button">{T("Sign up")}</button>
          </div>
        </div>
      </div>
    </div>
  );
};
