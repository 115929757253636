import React from "react";
import createCustomer from "@trunkery/internal/lib/graphql/operations/CreateCustomerMutation";
import { Checkbox } from "components/Checkbox";
import { FBoolean, FObject, FString, FormData, valid } from "@trunkery/internal/lib/formaline";
import { Footer } from "components/Footer";
import { FooterMenu } from "components/FooterMenu";
import { FormGroup } from "components/FormGroup";
import { FormInnerProps, formalizeExternal } from "@trunkery/internal/lib/formaline/react";
import { Link, navigate } from "gatsby";
import { RouteComponentProps } from "@reach/router";
import { RouteData } from "@trunkery/internal/lib/vatureTypes";
import { Spinner } from "components/Spinner";
import { globalAuthState } from "utils/globalAuthState";
import { mapFieldErrors } from "utils/mapFieldErrors";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { paths } from "utils/paths";
import { prettyPrintRequestResponseError, request } from "@lana-commerce/core/request";
import { useSiteData } from "utils/useSiteData";

import { T } from "./Signup.tlocale";

const signUpFormDefinition = FObject({
  name: FString(valid.nonEmpty, valid.maxLength250),
  email: FString(valid.nonEmpty, valid.email, valid.maxLength250),
  password: FString(valid.nonEmpty),
  accepts_marketing: FBoolean(),
});

interface SignUpFormProps {
  pending: boolean;
}

const SignUpForm = formalizeExternal<typeof signUpFormDefinition, SignUpFormProps>(
  class extends React.Component<FormInnerProps<typeof signUpFormDefinition, SignUpFormProps>> {
    render() {
      const {
        form: { name, email, password, accepts_marketing },
        pending,
        handleSubmit,
      } = this.props;
      return (
        <form onSubmit={handleSubmit}>
          <div className="centered-form">
            <div className="centered-form__content">
              <FormGroup field={name}>
                <div className="form-label">{T("Name")}</div>
                <input type="text" className="form-input" {...name.text} />
              </FormGroup>
              <FormGroup field={email}>
                <div className="form-label">{T("Email")}</div>
                <input type="email" className="form-input" {...email.text} />
              </FormGroup>
              <FormGroup field={password}>
                <div className="form-label">{T("Password")}</div>
                <input type="password" className="form-input" {...password.text} />
              </FormGroup>
              <div className="form-group">
                <Checkbox {...accepts_marketing.checkbox} label={T("Keep me updated")} />
              </div>
              <div className="form-group">
                <button
                  disabled={pending || !this.props.form.isValid}
                  className="banner-button banner-button--full-width"
                >
                  {pending ? <Spinner /> : T("Sign Up")}
                </button>
              </div>
              <div className="tacenter default-font">
                {T("Have an account already?")}{" "}
                <Link className="default-link" to={paths.accountSignin}>
                  {T("Sign In")}
                </Link>
              </div>
            </div>
          </div>
        </form>
      );
    }
  }
);

interface SignUpPageProps {
  siteData: RouteData.SiteData;
  goTo: (path: string) => void;
}

@observer
class SignUpPage extends React.Component<SignUpPageProps> {
  @observable pending = false;

  handleSubmit = async (data: typeof signUpFormDefinition.data) => {
    const {
      siteData: { config, shop },
      goTo,
    } = this.props;
    this.pending = true;
    const resp = await request(createCustomer)(
      {
        shopID: shop.id,
        data: {
          ...data,
          display_name: `${shop.name} Customer`,
        },
      },
      { url: `${config.host}/storefront.json` }
    );
    if (resp.kind === "data") {
      const token = resp.data[0].token;
      if (token) globalAuthState.login(token, data.email);
      goTo(paths.account);
    } else {
      if (!mapFieldErrors(resp, this.formData.propagateErrors)) {
        console.error(prettyPrintRequestResponseError(resp));
      }
    }
    this.pending = false;
  };

  formData = new FormData("SignUp", signUpFormDefinition, this.handleSubmit);
  render() {
    return (
      <>
        <div className="signup-header">{T("Sign Up")}</div>
        <SignUpForm pending={this.pending} formData={this.formData} initialValue={{ accepts_marketing: true }} />
      </>
    );
  }
}

export default (_props: RouteComponentProps) => {
  const siteData = useSiteData();
  return (
    <div className="page-with-menu">
      <SignUpPage siteData={siteData} goTo={navigate} />
      <div className="page-with-menu__content page-with-menu__content--no-padding page-with-menu__content--no-max-width">
        <FooterMenu />
        <Footer />
      </div>
    </div>
  );
};
