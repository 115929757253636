import confirmEmail from "gql/operations/ConfirmEmailMutation";
import getCountries from "gql/operations/GetCountriesQuery";
import getCustomer from "gql/operations/GetCustomerQuery";
import getCustomerBalance from "gql/operations/GetCustomerBalanceQuery";
import getGeoIP from "gql/operations/GetGeoIPQuery";
import getOrder from "gql/operations/GetOrderQuery";
import getOrderFulfillments from "gql/operations/GetOrderFulfillmentsQuery";
import getOrdersPage from "gql/operations/GetOrdersPageQuery";
import getPaymentSources from "gql/operations/GetPaymentSourcesQuery";
import isEqual from "lodash/isEqual";
import startsWith from "lodash/startsWith";
import { Auth } from "@trunkery/internal/lib/auth";
import { Environment } from "@trunkery/internal/lib/environment";
import { QueryBuilder } from "utils/queryBuilder";
import { navigate } from "gatsby";
import { paths } from "utils/paths";
import { request, requestMulti } from "@lana-commerce/core/request";

const hostToURL = (host: string) => `${host}/storefront.json`;

type PathMatcher = (path: string) => string[] | undefined;
type PathDataCacheLoader<T> = (env: Environment, auth: Auth, params: string[]) => Promise<DataResult<T>>;

type DataResult<T> =
  | {
      kind: "redirect";
      to: string;
    }
  | {
      kind: "data";
      data: T;
    };

function redirect(to: string) {
  return { kind: "redirect" as const, to };
}

function data<T>(data: T): DataResult<T> {
  return { kind: "data", data };
}

function regexpMatcher(regexp: RegExp): PathMatcher {
  return (path: string) => {
    const m = path.match(regexp);
    if (m) {
      return m.slice(1);
    }
    return undefined;
  };
}

function prefixMatcher(prefix: string): PathMatcher {
  return (path: string) => {
    return startsWith(path, prefix) ? [] : undefined;
  };
}

async function accountLoader(env: Environment, auth: Auth, _params: string[]) {
  if (auth.kind === "authorized") {
    const q = new QueryBuilder()
      .addQuery(getCustomer)({ shopID: env.shopID, customerID: auth.customerID })
      .addQuery(getOrdersPage)({ shopID: env.shopID })
      .assemble();
    return data(await requestMulti(q.query)(q.vars, { url: hostToURL(env.host), authToken: auth.jwt }));
  } else {
    return data(undefined);
  }
}

async function accountSignupLoader(_env: Environment, auth: Auth, _params: string[]) {
  if (auth.kind === "authorized") {
    return redirect(paths.account);
  }
  return data(true);
}

async function accountSigninLoader(_env: Environment, auth: Auth, _params: string[]) {
  if (auth.kind === "authorized") {
    return redirect(paths.account);
  }
  return data(true);
}

async function accountPasswordResetLoader(_env: Environment, _auth: Auth, _params: string[]) {
  return data(true);
}

async function accountOrdersLoader(env: Environment, auth: Auth, _params: string[]) {
  if (auth.kind === "authorized") {
    const q = new QueryBuilder()
      .addQuery(getCustomer)({ shopID: env.shopID, customerID: auth.customerID })
      .addQuery(getOrdersPage)({ shopID: env.shopID })
      .assemble();
    return data(await requestMulti(q.query)(q.vars, { url: hostToURL(env.host), authToken: auth.jwt }));
  } else {
    return redirect(paths.accountSignin);
  }
}

async function accountAddressesLoader(env: Environment, auth: Auth, _params: string[]) {
  if (auth.kind === "authorized") {
    const q = new QueryBuilder()
      .addQuery(getCustomer)({ shopID: env.shopID, customerID: auth.customerID })
      .addQuery(getGeoIP)()
      .addQuery(getCountries)({ shopID: env.shopID })
      .assemble();
    return data(await requestMulti(q.query)(q.vars, { url: hostToURL(env.host), authToken: auth.jwt }));
  } else {
    return redirect(paths.accountSignin);
  }
}

async function accountPaymentMethodsLoader(env: Environment, auth: Auth, _params: string[]) {
  if (auth.kind === "authorized") {
    const q = new QueryBuilder()
      .addQuery(getCustomer)({ shopID: env.shopID, customerID: auth.customerID })
      .addQuery(getPaymentSources)({ shopID: env.shopID })
      .assemble();
    return data(await requestMulti(q.query)(q.vars, { url: hostToURL(env.host), authToken: auth.jwt }));
  } else {
    return redirect(paths.accountSignin);
  }
}

async function accountStoreCreditLoader(env: Environment, auth: Auth, _params: string[]) {
  if (auth.kind === "authorized") {
    const q = new QueryBuilder().addQuery(getCustomerBalance)({ shopID: env.shopID }).assemble();
    return data(await requestMulti(q.query)(q.vars, { url: hostToURL(env.host), authToken: auth.jwt }));
  } else {
    return redirect(paths.accountSignin);
  }
}

async function accountProfileLoader(env: Environment, auth: Auth, _params: string[]) {
  if (auth.kind === "authorized") {
    const q = new QueryBuilder().addQuery(getCustomer)({ shopID: env.shopID, customerID: auth.customerID }).assemble();
    return data(await requestMulti(q.query)(q.vars, { url: hostToURL(env.host), authToken: auth.jwt }));
  } else {
    return redirect(paths.accountSignin);
  }
}

async function accountOrdersOrderLoader(env: Environment, auth: Auth, params: string[]) {
  if (auth.kind === "authorized") {
    const q = new QueryBuilder()
      .addQuery(getOrder)({ shopID: env.shopID, id: params[0] })
      .addQuery(getOrderFulfillments)({ shopID: env.shopID, id: params[0] })
      .assemble();
    return data(await requestMulti(q.query)(q.vars, { url: hostToURL(env.host), authToken: auth.jwt }));
  } else {
    return redirect(paths.accountSignin);
  }
}

async function accountNewPasswordLoader(_env: Environment, _auth: Auth, params: string[]) {
  return data(params[0]);
}

async function accountConfirmLoader(env: Environment, _auth: Auth, params: string[]) {
  const resp = await request(confirmEmail)(
    { shopID: env.shopID, token: params[0] },
    { url: `${env.host}/storefront.json` }
  );
  return data(resp.kind === "data" ? true : false);
}

class CacheNode<T> {
  private lastData: DataResult<T> | undefined;
  private lastParams: string[] | undefined;
  private lastAuth: Auth | undefined;
  private lastKey: string | undefined;
  constructor(
    private loader: PathDataCacheLoader<T>,
    private matcher: PathMatcher
  ) {}

  match(path: string): string[] | undefined {
    return this.matcher(path);
  }

  async prefetch(env: Environment, auth: Auth, params: string[], locationKey: string | undefined) {
    this.lastData = await this.loader(env, auth, params);
    this.lastParams = params;
    this.lastAuth = auth;
    this.lastKey = locationKey || "";
    return this.lastData;
  }

  get(
    env: Environment,
    auth: Auth,
    path: string,
    locationKey: string | undefined,
    callback?: () => void
  ): T | undefined {
    const ld = this.lastData;
    const lp = this.lastParams;
    const la = this.lastAuth;
    const lk = this.lastKey;
    const m = this.matcher(path);
    if (!m) throw new Error("when using get(), make sure you use it at the right path (current path: " + path + ")");
    if (isEqual(lp, m) && isEqual(la, auth) && ld && lk === locationKey) {
      if (ld.kind === "redirect") {
        setTimeout(() => {
          navigate(ld.to);
        }, 0);
        return undefined;
      } else {
        return ld.data;
      }
    } else {
      this.lastData = undefined;
      this.lastParams = undefined;
      this.lastAuth = undefined;
      this.lastKey = undefined;
      this.prefetch(env, auth, m, locationKey || "").then(callback);
      return undefined;
    }
  }
}

class GlobalDataCache {
  accountCache = new CacheNode(accountLoader, prefixMatcher(paths.account));
  accountSignupCache = new CacheNode(accountSignupLoader, prefixMatcher(paths.accountSignup));
  accountSigninCache = new CacheNode(accountSigninLoader, prefixMatcher(paths.accountSignin));
  accountPasswordResetCache = new CacheNode(accountPasswordResetLoader, prefixMatcher(paths.accountPasswordReset));
  accountOrdersCache = new CacheNode(accountOrdersLoader, prefixMatcher(paths.accountOrders));
  accountAddressesCache = new CacheNode(accountAddressesLoader, prefixMatcher(paths.accountAddresses));
  accountPaymentMethodsCache = new CacheNode(accountPaymentMethodsLoader, prefixMatcher(paths.accountPaymentMethods));
  accountStoreCreditCache = new CacheNode(accountStoreCreditLoader, prefixMatcher(paths.accountStoreCredit));
  accountProfileCache = new CacheNode(accountProfileLoader, prefixMatcher(paths.accountProfile));
  accountOrderCache = new CacheNode(accountOrdersOrderLoader, regexpMatcher(/\/account\/orders\/(or_[^/]+)/));
  accountNewPasswordCache = new CacheNode(accountNewPasswordLoader, regexpMatcher(/\/account\/new-password\/([^/]+)/));
  accountConfirmCache = new CacheNode(accountConfirmLoader, regexpMatcher(/\/account\/confirm\/([^/]+)/));

  allCaches = [
    this.accountOrderCache,
    this.accountNewPasswordCache,
    this.accountConfirmCache,
    this.accountSignupCache,
    this.accountSigninCache,
    this.accountPasswordResetCache,
    this.accountOrdersCache,
    this.accountAddressesCache,
    this.accountPaymentMethodsCache,
    this.accountStoreCreditCache,
    this.accountProfileCache,
    this.accountCache,
  ];

  async prefetch(path: string, auth: Auth, env: Environment, locationKey: string | undefined) {
    for (const c of this.allCaches) {
      const m = c.match(path);
      if (m !== undefined) {
        await c.prefetch(env, auth, m, locationKey);
        return;
      }
    }
  }
}

export const globalDataCache = new GlobalDataCache();
