import Addresses from "dynamic/account/Addresses";
import Confirm from "dynamic/account/Confirm";
import Index from "dynamic/account/Index";
import Order from "dynamic/account/Order";
import Orders from "dynamic/account/Orders";
import PasswordReset from "dynamic/account/PasswordReset";
import PaymentMethods from "dynamic/account/PaymentMethods";
import Profile from "dynamic/account/Profile";
import React from "react";
import Signin from "dynamic/account/Signin";
import Signup from "dynamic/account/Signup";
import StoreCredit from "dynamic/account/StoreCredit";
import { Layout } from "components/Layout";
import { PrefetchRouter } from "components/PrefetchRouter";
import { paths } from "utils/paths";

export default () => {
  const isSSR = typeof document === "undefined";
  return (
    <Layout>
      {!isSSR ? (
        <PrefetchRouter>
          <Order path="/account/orders/:orderID" />
          <Confirm path="/account/confirm/:token" />
          <Signup path={paths.accountSignup} />
          <Signin path={paths.accountSignin} />
          <PasswordReset path={paths.accountPasswordReset} />
          <Orders path={paths.accountOrders} />
          <Addresses path={paths.accountAddresses} />
          <PaymentMethods path={paths.accountPaymentMethods} />
          <StoreCredit path={paths.accountStoreCredit} />
          <Profile path={paths.accountProfile} />
          <Index path={paths.account} />
        </PrefetchRouter>
      ) : null}
    </Layout>
  );
};
