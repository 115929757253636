import CurrencyPrice from "@trunkery/internal/lib/vature-gen/fragments/CurrencyPrice";
import File from "@trunkery/internal/lib/vature-gen/fragments/File";
import Gateway from "@trunkery/internal/lib/vature-gen/fragments/Gateway";
import GatewayManual from "@trunkery/internal/lib/vature-gen/fragments/GatewayManual";
import GatewayPaypal from "@trunkery/internal/lib/vature-gen/fragments/GatewayPaypal";
import GatewayStripe from "@trunkery/internal/lib/vature-gen/fragments/GatewayStripe";
import GatewayTest from "@trunkery/internal/lib/vature-gen/fragments/GatewayTest";
import MediaFile from "@trunkery/internal/lib/vature-gen/fragments/MediaFile";
import TieredPricing from "@trunkery/internal/lib/vature-gen/fragments/TieredPricing";
import TieredPricingTier from "@trunkery/internal/lib/vature-gen/fragments/TieredPricingTier";
import Variant from "@trunkery/internal/lib/vature-gen/fragments/Variant";
import VariantOption from "@trunkery/internal/lib/vature-gen/fragments/VariantOption";

import FullAddress from "../fragments/FullAddress";
import LineItemProduct from "../fragments/LineItemProduct";
import PaymentDetail from "../fragments/PaymentDetail";
import StorefrontCustomFieldObjects from "../fragments/StorefrontCustomFieldObjects";
import StorefrontOrder from "../fragments/StorefrontOrder";
import type { GetOrderQueryMeta } from "../types";

export default (CurrencyPrice +
  File +
  FullAddress +
  Gateway +
  GatewayManual +
  GatewayPaypal +
  GatewayStripe +
  GatewayTest +
  LineItemProduct +
  MediaFile +
  PaymentDetail +
  StorefrontCustomFieldObjects +
  StorefrontOrder +
  TieredPricing +
  TieredPricingTier +
  Variant +
  VariantOption +
  "query GetOrder($shopID:String!$id:String!$uuid:String){storefrontOrders(shop_id:$shopID ids:[$id]uuid:$uuid){...StorefrontOrder}}") as unknown as GetOrderQueryMeta;
