import * as React from "react";
import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import getCountries from "gql/operations/GetCountriesQuery";
import sortBy from "lodash/sortBy";
import { GetCountriesQuery, InfoCountryFragment } from "gql/types";
import { RouteData } from "@trunkery/internal/lib/vatureTypes";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { request } from "@lana-commerce/core/request";

type Country = NonNullable<GetCountriesQuery["countries"]>[0];

interface CountrySelectGenericProps {
  siteData: RouteData.SiteData;
  renderComponent: (countries: Country[]) => JSX.Element;
  countries?: InfoCountryFragment[];
}

@observer
export class CountrySelectGeneric extends React.Component<CountrySelectGenericProps> {
  @observable.ref private countries: Country[] = [];

  private async fetchCountries() {
    const { siteData } = this.props;
    const resp = await request(getCountries)(
      { shopID: siteData.shop.id },
      { url: `${siteData.config.host}/storefront.json` }
    );
    if (resp.kind === "data") {
      this.countries = sortBy(resp.data, (d) => d.name);
    }
  }

  resolveCountry(value: string) {
    return find(this.countries, (c) => c.code === value);
  }

  componentDidMount() {
    if (!this.props.countries) {
      this.fetchCountries();
    } else {
      this.countries = sortBy(cloneDeep(this.props.countries), (d) => d.name);
    }
  }

  render() {
    return this.props.renderComponent(this.countries);
  }
}
