import * as React from "react";
import applyGiftCard from "gql/operations/ApplyGiftCardMutation";
import find from "lodash/find";
import { AccountBackArrow } from "components/AccountBackArrow";
import { AccountSidebar } from "components/AccountSidebar";
import { FObject, FString, FormData, codes, valid } from "@trunkery/internal/lib/formaline";
import { Footer } from "components/Footer";
import { FooterMenu } from "components/FooterMenu";
import { FormGroup } from "components/FormGroup";
import { FormInnerProps, formalizeExternal } from "@trunkery/internal/lib/formaline/react";
import { GetCustomerBalanceQuery } from "gql/types";
import { Money } from "components/Money";
import {
  RequestResponseError,
  RequestResponseMulti,
  prettyPrintRequestResponseError,
  request,
} from "@lana-commerce/core/request";
import { RouteComponentProps } from "@reach/router";
import { RouteData } from "@trunkery/internal/lib/vatureTypes";
import { Spinner } from "components/Spinner";
import { authJWT } from "@trunkery/internal/lib/auth";
import { environmentFromSiteData } from "utils/environmentFromSiteData";
import { globalAuthState } from "utils/globalAuthState";
import { globalDataCache } from "utils/globalDataCache";
import { navigate } from "gatsby";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { paths } from "utils/paths";
import { preventConcurrency } from "utils/preventConcurrency";
import { respItems } from "utils/respItems";
import { useForceUpdate } from "utils/useForceUpdate";
import { usePrefetchLocation } from "components/PrefetchRouter";
import { useSiteData } from "utils/useSiteData";

import { T } from "./StoreCredit.tlocale";

type DataType = RequestResponseMulti<GetCustomerBalanceQuery>;

//==========================================================================================
//==========================================================================================
//==========================================================================================

const redeemCardFormDefinition = FObject({
  gift_card_code: FString(valid.nonEmpty),
});

interface RedeemCardFormProps {
  pending: boolean;
  onCancel: () => void;
}

const RedeemCardForm = formalizeExternal<typeof redeemCardFormDefinition, RedeemCardFormProps>(
  class extends React.Component<FormInnerProps<typeof redeemCardFormDefinition, RedeemCardFormProps>> {
    render() {
      const {
        form: { gift_card_code },
        pending,
        onCancel,
        handleSubmit,
      } = this.props;
      return (
        <form onSubmit={handleSubmit} className="account-page-form">
          <FormGroup field={gift_card_code}>
            <div className="form-label">{T("Redeem Gift Card")}</div>
            <input type="text" className="form-input" {...gift_card_code.text} />
          </FormGroup>
          <div className="account-page-form__buttons">
            <button
              type="button"
              className="banner-button banner-button--small banner-button--no-min-width"
              onClick={onCancel}
              disabled={pending}
            >
              {T("Cancel")}
            </button>
            <button className="banner-button banner-button--small" disabled={pending || !this.props.form.isValid}>
              {pending ? <Spinner small /> : T("Redeem")}
            </button>
          </div>
        </form>
      );
    }
  }
);

//==========================================================================================
//==========================================================================================
//==========================================================================================

interface StoreCreditPageProps {
  data: DataType;
  siteData: RouteData.SiteData;
  goTo: (path: string) => void;
}

@observer
class StoreCreditPage extends React.Component<StoreCreditPageProps> {
  @observable pending = false;
  @observable formVisible = false;

  handleRedeemCardClick = () => {
    this.formVisible = true;
  };

  handleRedeemCardFormCancel = () => {
    this.formVisible = false;
  };

  getShop() {
    const { siteData } = this.props;
    return siteData.shop;
  }

  getBalance() {
    return respItems(this.props.data)?.storefrontCustomerBalance || undefined;
  }

  getDefaultCurrencyBalance() {
    const shop = this.getShop();
    const balance = this.getBalance();
    if (!shop || !balance) return undefined;

    return find(balance.balance, (b) => b.currency === shop.currency);
  }

  handleAPIError(e?: RequestResponseError) {
    if (!e) return;
    if (e.kind !== "error") return;
    const err = e.error.apiError;
    if (!err) return;
    if (err.code === codes.NotFound) {
      this.formData.propagateErrors([
        {
          problem: undefined,
          message: T("Gift card code expired or already used"),
          field: "gift_card_code",
        },
      ]);
    }
  }

  handleSubmit = preventConcurrency(async (data: typeof redeemCardFormDefinition.data) => {
    const {
      siteData: { config, shop },
      goTo,
    } = this.props;
    this.pending = true;
    const resp = await request(applyGiftCard)(
      {
        shopID: shop.id,
        data,
      },
      { url: `${config.host}/storefront.json`, authToken: authJWT(globalAuthState.auth) }
    );
    if (resp.kind === "data") {
      goTo(paths.accountStoreCredit);
      this.formVisible = false;
    } else {
      this.handleAPIError(resp);
      console.error(prettyPrintRequestResponseError(resp));
    }
    this.pending = false;
  });

  formData = new FormData("Redeem", redeemCardFormDefinition, this.handleSubmit);

  renderZero() {
    return !this.formVisible ? (
      <div className="account-page-centered margin-block">{T("You don’t have any store credit yet.")}</div>
    ) : null;
  }

  render() {
    const env = environmentFromSiteData(this.props.siteData);
    const shop = this.getShop();
    const balance = this.getBalance();
    if (!shop || !balance) return null;

    const dcb = this.getDefaultCurrencyBalance();
    return (
      <div className="account-layout">
        <AccountSidebar active="store-credit" />
        <div className="account-layout__content">
          <div className="account-page-header">
            <AccountBackArrow />
            <div className="account-page-header__title">{T("Store Credit")}</div>
          </div>
          {!dcb ? (
            this.renderZero()
          ) : (
            <div className="store-credit-balance margin-block">
              <div className="store-credit-balance__label">{T("Balance")}</div>
              <div className="store-credit-balance__value">
                <Money env={env} currency={dcb.currency} value={dcb.amount} />
              </div>
            </div>
          )}
          {!this.formVisible ? (
            <div className="account-page-centered">
              <a className="default-link" onClick={this.handleRedeemCardClick}>
                {T("Redeem Gift Card")}
              </a>
            </div>
          ) : (
            <RedeemCardForm
              formData={this.formData}
              onCancel={this.handleRedeemCardFormCancel}
              pending={this.pending}
            />
          )}
        </div>
      </div>
    );
  }
}

export default (_props: RouteComponentProps) => {
  const siteData = useSiteData();
  const env = environmentFromSiteData(siteData);
  const location = usePrefetchLocation();
  const forceUpdate = useForceUpdate();
  const data = globalDataCache.accountStoreCreditCache.get(
    env,
    globalAuthState.auth,
    location.pathname,
    location.key,
    forceUpdate
  );
  if (!data) return null;
  return (
    <div className="page-with-menu">
      <StoreCreditPage data={data} siteData={siteData} goTo={navigate} />
      <div className="page-with-menu__content page-with-menu__content--no-padding page-with-menu__content--no-max-width">
        <FooterMenu />
        <Footer />
      </div>
    </div>
  );
};
