import { StorefrontOrderFragment } from "gql/types";

import { T } from "./niceCancelReasonText.tlocale";

export function niceCancelReasonText(f: NonNullable<StorefrontOrderFragment["cancel_reason"]>) {
  switch (f) {
    case "customer":
      return T("Customer");
    case "fraud":
      return T("Fraud");
    case "inventory":
      return T("Inventory");
    case "malformed_shipping_address":
      return T("Malformed Shipping Address");
    case "other":
      return T("Other");
    case "price_error":
      return T("Price Error");
    case "risk":
      return T("Risk");
    case "shipping_price_error":
      return T("Shipping Price Error");
    case "tax_error":
      return T("Tax Error");
    case "undeliverable_shipping_address":
      return T("Undeliverable Shipping Address");
    case "unsupported_po_box_address":
      return T("Unsupported PO Box Address");
  }
}
