import * as React from "react";
import classnames from "classnames";
import map from "lodash/map";
import { AccountBackArrow } from "components/AccountBackArrow";
import { AccountSidebar } from "components/AccountSidebar";
import { DateTime } from "luxon";
import { GetCustomerQuery, GetOrdersPageQuery } from "gql/types";
import { Link } from "gatsby";
import { Money } from "components/Money";
import { RequestResponseMulti } from "@lana-commerce/core/request";
import { environmentFromSiteData } from "utils/environmentFromSiteData";
import { niceFulfillmentText } from "utils/niceFulfillmentText";
import { paths } from "utils/paths";
import { useSiteData } from "utils/useSiteData";

import { T } from "./OrdersPage.tlocale";

type DataType = RequestResponseMulti<GetCustomerQuery & GetOrdersPageQuery>;

interface OrdersPageProps {
  desktopOnly?: boolean;
  data: DataType;
}

export const OrdersPage = (props: OrdersPageProps) => {
  const siteData = useSiteData();
  const env = environmentFromSiteData(siteData);
  const getOrders = () => {
    const { data } = props;
    if (data) {
      const ordersPage = data.kind === "data_or_error" ? data.data?.storefrontOrdersPage : undefined;
      if (ordersPage) {
        return ordersPage.items;
      }
    }
    return [];
  };
  const { desktopOnly } = props;
  const orders = getOrders();
  return (
    <div className={classnames("account-layout", { "desktop-only": desktopOnly })}>
      <AccountSidebar active="orders" />
      <div className="account-layout__content">
        <div className="account-page-header">
          <AccountBackArrow />
          <div className="account-page-header__title">{T("Orders")}</div>
        </div>
        {orders.length === 0 ? (
          <div className="account-page-centered">{T("You haven't placed any orders yet.")}</div>
        ) : (
          <div>
            <table className="account-table">
              <thead>
                <tr>
                  <th>{T("Order")}</th>
                  <th>{T("Date")}</th>
                  <th>{T("Total")}</th>
                  <th>{T("Fulfillment")}</th>
                </tr>
              </thead>
              <tbody>
                {map(orders, (o) => (
                  <tr key={o.id}>
                    <td>
                      <Link className="default-link" to={paths.accountOrder(o.id)}>
                        #{o.number}
                      </Link>
                    </td>
                    <td>{DateTime.fromISO(o.paid_at || "").toLocaleString(DateTime.DATE_MED)}</td>
                    <td>
                      <Money env={env} currency={env.shopCurrency} value={o.total_price} />
                    </td>
                    <td>{niceFulfillmentText(o.fulfillment_status)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="account-list">
              {map(orders, (o) => (
                <div className="account-list__item" key={o.id}>
                  <div className="account-list__line">
                    <Link className="default-link" to={paths.accountOrder(o.id)}>
                      #{o.number}
                    </Link>
                  </div>
                  <div className="account-list__line">
                    <span className="account-list__label">{T("Date")}</span>{" "}
                    {DateTime.fromISO(o.paid_at || "").toLocaleString(DateTime.DATE_MED)}
                  </div>
                  <div className="account-list__line">
                    <span className="account-list__label">{T("Total")}</span>{" "}
                    <Money env={env} currency={env.shopCurrency} value={o.total_price} />
                  </div>
                  <div className="account-list__line">
                    <span className="account-list__label">{T("Fulfillment")}</span>{" "}
                    {niceFulfillmentText(o.fulfillment_status)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
