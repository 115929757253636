import * as React from "react";
import modifyCustomer from "gql/operations/ModifyCustomerMutation";
import { AccountBackArrow } from "components/AccountBackArrow";
import { AccountSidebar } from "components/AccountSidebar";
import { Checkbox } from "components/Checkbox";
import { FBoolean, FObject, FString, FormData, valid } from "@trunkery/internal/lib/formaline";
import { Footer } from "components/Footer";
import { FooterMenu } from "components/FooterMenu";
import { FormGroup } from "components/FormGroup";
import { FormInnerProps, formalizeExternal } from "@trunkery/internal/lib/formaline/react";
import { FullAddressFragment, GetCustomerQuery } from "gql/types";
import { PhoneInput } from "components/PhoneInput";
import { RequestResponseMulti, prettyPrintRequestResponseError, request } from "@lana-commerce/core/request";
import { RouteComponentProps } from "@reach/router";
import { RouteData } from "@trunkery/internal/lib/vatureTypes";
import { Spinner } from "components/Spinner";
import { authCustomerID, authJWT } from "@trunkery/internal/lib/auth";
import { customerDefaultAddress } from "utils/customerDefaultAddress";
import { environmentFromSiteData } from "utils/environmentFromSiteData";
import { globalAuthState } from "utils/globalAuthState";
import { globalDataCache } from "utils/globalDataCache";
import { navigate } from "gatsby";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { paths } from "utils/paths";
import { preventConcurrency } from "utils/preventConcurrency";
import { respItems } from "utils/respItems";
import { useForceUpdate } from "utils/useForceUpdate";
import { usePrefetchLocation } from "components/PrefetchRouter";
import { useSiteData } from "utils/useSiteData";

import { T } from "./Profile.tlocale";

type DataType = RequestResponseMulti<GetCustomerQuery>;

//==========================================================================================
//==========================================================================================
//==========================================================================================

const customerProfileFormDefinition = FObject({
  name: FString(valid.nonEmpty, valid.maxLength250),
  display_name: FString(valid.maxLength250),
  mobile: FString(),
  mobile_notifications: FBoolean(),
  accepts_marketing: FBoolean(),
});

interface CustomerProfileFormProps {
  pending: boolean;
  defaultAddress?: FullAddressFragment;
}

const CustomerProfileForm = formalizeExternal<typeof customerProfileFormDefinition, CustomerProfileFormProps>(
  class extends React.Component<FormInnerProps<typeof customerProfileFormDefinition, CustomerProfileFormProps>> {
    render() {
      const {
        form: { name, display_name, mobile, mobile_notifications, accepts_marketing },
        pending,
        handleSubmit,
        defaultAddress,
      } = this.props;
      const defaultAddressCountryCode =
        defaultAddress && defaultAddress.country ? defaultAddress.country.code : undefined;
      return (
        <form onSubmit={handleSubmit} className="account-page-form">
          <FormGroup field={name}>
            <div className="form-label">{T("Name")}</div>
            <input type="text" className="form-input" {...name.text} />
          </FormGroup>
          <FormGroup field={display_name}>
            <div className="form-label">{T("Display Name")}</div>
            <input type="text" className="form-input" {...display_name.text} />
          </FormGroup>
          <FormGroup field={mobile}>
            <div className="form-label">{T("Mobile Phone")}</div>
            <PhoneInput
              countryCode={defaultAddressCountryCode}
              {...mobile.raw}
              renderInput={(props) => <input type="tel" className="form-input" {...props} />}
            />
          </FormGroup>
          <div className="form-group">
            <Checkbox label={T("Receive SMS notifications")} {...mobile_notifications.checkbox} />
          </div>
          <div className="form-group">
            <Checkbox label={T("Keep me updated with exclusive offers")} {...accepts_marketing.checkbox} />
          </div>
          <div className="account-page-form__buttons">
            <button className="banner-button banner-button--small" disabled={pending || !this.props.form.isValid}>
              {pending ? <Spinner small /> : T("Save")}
            </button>
          </div>
        </form>
      );
    }
  }
);

//==========================================================================================
//==========================================================================================
//==========================================================================================

interface ProfilePageProps {
  data: DataType;
  siteData: RouteData.SiteData;
  goTo: (path: string) => void;
}

@observer
class ProfilePage extends React.Component<ProfilePageProps> {
  @observable pending = false;

  getCustomer() {
    return respItems(this.props.data)?.storefrontCustomers?.[0] || undefined;
  }

  handleSubmit = preventConcurrency(async (data: typeof customerProfileFormDefinition.data) => {
    const {
      siteData: { config, shop },
      goTo,
    } = this.props;
    this.pending = true;
    const resp = await request(modifyCustomer)(
      {
        shopID: shop.id,
        id: authCustomerID(globalAuthState.auth) || "",
        data,
      },
      { url: `${config.host}/storefront.json`, authToken: authJWT(globalAuthState.auth) }
    );
    if (resp.kind === "data") {
      goTo(paths.accountProfile);
    } else {
      console.error(prettyPrintRequestResponseError(resp));
    }
    this.pending = false;
  });

  formData = new FormData("Profile", customerProfileFormDefinition, this.handleSubmit);

  render() {
    const customer = this.getCustomer();
    if (!customer) return null;
    const defaultAddress = customerDefaultAddress(customer);
    return (
      <div className="account-layout">
        <AccountSidebar active="profile" />
        <div className="account-layout__content">
          <div className="account-page-header">
            <AccountBackArrow />
            <div className="account-page-header__title">{T("Profile")}</div>
          </div>
          <CustomerProfileForm
            defaultAddress={defaultAddress}
            formData={this.formData}
            pending={this.pending}
            initialValue={customer}
          />
        </div>
      </div>
    );
  }
}

export default (_props: RouteComponentProps) => {
  const siteData = useSiteData();
  const env = environmentFromSiteData(siteData);
  const location = usePrefetchLocation();
  const forceUpdate = useForceUpdate();
  const data = globalDataCache.accountProfileCache.get(
    env,
    globalAuthState.auth,
    location.pathname,
    location.key,
    forceUpdate
  );
  if (!data) return null;
  return (
    <div className="page-with-menu">
      <ProfilePage data={data} siteData={siteData} goTo={navigate} />
      <div className="page-with-menu__content page-with-menu__content--no-padding page-with-menu__content--no-max-width">
        <FooterMenu />
        <Footer />
      </div>
    </div>
  );
};
