type ArgumentTypes<T> = T extends (...args: infer U) => any ? U : never;
type PromiseType<T extends Promise<any>> = T extends Promise<infer R> ? R : any;

type Result<T> =
  | {
      kind: "canceled";
    }
  | {
      kind: "valid";
      value: T;
    };

// TODO: better explanation
// Wraps an async function in such a way that if while request #1 is running user makes request #2, request #1
// will return undefined as a result. Last request always returns an unmodified value.
export function lastValue<T extends (...args: any[]) => Promise<any>>(f: T) {
  type Ret = ReturnType<T>;
  type Val = PromiseType<Ret>;
  type Args = ArgumentTypes<T>;
  let current: Promise<any> | undefined;
  return async (...args: Args): Promise<Result<Val>> => {
    const promise = f(...args);
    current = promise;
    const value = await promise;
    return promise === current ? { kind: "valid", value } : { kind: "canceled" };
  };
}
