import * as React from "react";
import createPaymentSource from "gql/operations/CreatePaymentSourceMutation";
import deletePaymentSource from "gql/operations/DeletePaymentSourceMutation";
import every from "lodash/every";
import find from "lodash/find";
import map from "lodash/map";
import modifyCustomer from "gql/operations/ModifyCustomerMutation";
import { AccountBackArrow } from "components/AccountBackArrow";
import { AccountSidebar } from "components/AccountSidebar";
import { CCBrand, desaturateBrand, getBrandForStripeBrand, getNiceBrandName } from "@trunkery/internal/lib/ccBrand";
import { Checkbox } from "components/Checkbox";
import { CountrySelect } from "components/CountrySelect";
import {
  CustomerPaymentSourceFragment,
  GetCustomerQuery,
  GetPaymentSourcesQuery,
  StorefrontCustomerFragment,
} from "gql/types";
import { Environment } from "@trunkery/internal/lib/environment";
import { FBoolean, FObject, FString, FormData, codes, valid } from "@trunkery/internal/lib/formaline";
import { Footer } from "components/Footer";
import { FooterMenu } from "components/FooterMenu";
import { FormGroup } from "components/FormGroup";
import { FormInnerProps, formalizeExternal } from "@trunkery/internal/lib/formaline/react";
import { PaymentMethodCreateParams } from "@stripe/stripe-js";
import { ProvinceSelect } from "components/ProvinceSelect";
import {
  RequestResponseError,
  RequestResponseMulti,
  prettyPrintRequestResponseError,
  request,
} from "@lana-commerce/core/request";
import { RouteComponentProps } from "@reach/router";
import { RouteData } from "@trunkery/internal/lib/vatureTypes";
import { Spinner } from "components/Spinner";
import { authJWT } from "@trunkery/internal/lib/auth";
import { environmentFromSiteData } from "utils/environmentFromSiteData";
import { extractStripeErrorAndPropagateToFormOrRethrow, stripeErrorForCode } from "@trunkery/internal/lib/stripeErrors";
import { getBrandLogo } from "@trunkery/internal/lib/ccLogo";
import { getStripe } from "@trunkery/internal/lib/stripe";
import { globalAuthState } from "utils/globalAuthState";
import { globalDataCache } from "utils/globalDataCache";
import { navigate } from "gatsby";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { paths } from "utils/paths";
import { preventConcurrency } from "utils/preventConcurrency";
import { respItems } from "utils/respItems";
import { useForceUpdate } from "utils/useForceUpdate";
import { usePrefetchLocation } from "components/PrefetchRouter";
import { useSiteData } from "utils/useSiteData";

import { T } from "./PaymentMethods.tlocale";

type DataType = RequestResponseMulti<GetCustomerQuery & GetPaymentSourcesQuery>;

//==========================================================================================
//==========================================================================================
//==========================================================================================

const cardFormDefinition = FObject({
  name: FString(),
  address: FString(),
  city: FString(),
  zip: FString(),
  country: FString(),
  province: FString(),
  as_default: FBoolean(),

  // These fields are fake and don't contain any useful data, we use them as error reporting tool.
  // Because our form lib allows you to associate errors with a field, these serve as such
  // attachment points. All the CC data is stored solely on stripe elements.
  ccNumber: FBoolean(),
  ccExpiry: FBoolean(),
  ccCVC: FBoolean(),

  // When all stripe fields report themselves as "complete", we set this meaningless field to some
  // non-empty value. Useful for blocking form's submit button.
  ccValid: FString(valid.ignoreError, valid.nonEmpty),
});

function creditCardFormToPaymentMethodDetails(
  data: typeof cardFormDefinition.data
): PaymentMethodCreateParams.BillingDetails {
  return {
    name: data.name,
    address: {
      line1: data.address,
      line2: "",
      city: data.city,
      postal_code: data.zip,
      country: data.country,
      state: data.province,
    },
  };
}

interface CardFormProps {
  onElement: (v: any) => void;
  onCancel: () => void;
  pending: boolean;
  env: Environment;
}

class CardFormClass extends React.Component<FormInnerProps<typeof cardFormDefinition, CardFormProps>> {
  @observable brand: CCBrand = "unknown";
  ccNumberRef: HTMLDivElement | null = null;
  ccExpiryRef: HTMLDivElement | null = null;
  ccCVVRef: HTMLDivElement | null = null;

  elements: any[] = [];

  acquireCCNumberRef = (e: HTMLDivElement | null) => {
    this.ccNumberRef = e;
  };
  acquireCCExpiryRef = (e: HTMLDivElement | null) => {
    this.ccExpiryRef = e;
  };
  acquireCCCVVRef = (e: HTMLDivElement | null) => {
    this.ccCVVRef = e;
  };

  componentDidMount() {
    const { ccNumberRef, ccExpiryRef, ccCVVRef } = this;
    if (!ccNumberRef || !ccExpiryRef || !ccCVVRef) {
      console.warn("null refs", !!ccNumberRef, !!ccExpiryRef, !!ccCVVRef);
      return;
    }

    const placeholderOpts = (color: string) => ({
      classes: {
        invalid: "form-input-stripe--has-error",
      },
      style: {
        base: {
          fontFamily: "Open Sans, sans-serif",
          fontSize: "16px",
          lineHeight: "22px",
          color: "#000000",
          "::placeholder": {
            color,
          },
        },
        invalid: {
          color: "#c63f4f",
        },
      },
    });

    // const optsHiddenPlaceholder = placeholderOpts('#ffffff');
    const optsVisiblePlaceholder = placeholderOpts("#788995");
    // const optsNoPlaceholder = { ...optsHiddenPlaceholder, placeholder: '' };

    const elements = getStripe(this.props.env).elements({
      fonts: [{ cssSrc: "https://fonts.googleapis.com/css?family=Open+Sans" }],
    });

    const status = {
      number: false,
      expiry: false,
      cvc: false,
    };

    const { ccValid } = this.props.form;
    const processField = (field: any, e: any, name: keyof typeof status) => {
      if (e.error) {
        const v = stripeErrorForCode(e.error.code);
        if (v) field.propagateErrors([v], v.field);
        else console.error(e.error);
      } else {
        field.propagateErrors([]);
      }
      status[name] = e.complete;
      const isValid = every(status);
      if (isValid && ccValid.value === "") ccValid.set("1");
      if (!isValid && ccValid.value === "1") ccValid.set("");
    };

    const number = elements.create("cardNumber", optsVisiblePlaceholder);
    this.props.onElement(number);
    number.on("change", (e: any) => {
      this.brand = getBrandForStripeBrand(e.brand);
      processField(this.props.form.ccNumber, e, "number");
    });
    number.mount(ccNumberRef);
    this.elements.push(number);

    const expiry = elements.create("cardExpiry", optsVisiblePlaceholder);
    expiry.on("change", (e: any) => {
      processField(this.props.form.ccExpiry, e, "expiry");
    });
    expiry.mount(ccExpiryRef);
    this.elements.push(expiry);

    const cvc = elements.create("cardCvc", optsVisiblePlaceholder);
    cvc.on("change", (e: any) => {
      processField(this.props.form.ccCVC, e, "cvc");
    });
    cvc.mount(ccCVVRef);
    this.elements.push(cvc);
  }

  componentWillUnmount() {
    for (const e of this.elements) {
      e.destroy();
    }
    this.elements = [];
  }

  render() {
    const {
      form: { name, address, city, zip, country, province, ccNumber, ccExpiry, ccCVC, as_default },
      pending,
      onCancel,
      handleSubmit,
    } = this.props;
    return (
      <>
        <div className="margin-block default-font">
          {T("We securely accept all major credit cards. Your details are securely stored by our payment processor.")}
        </div>
        <form onSubmit={handleSubmit} className="account-page-form account-page-form--wider">
          <div className="credit-card-images">
            <img className={desaturateBrand("visa", this.brand, true)} src={getBrandLogo("visa")} />
            <img className={desaturateBrand("mastercard", this.brand, true)} src={getBrandLogo("mastercard")} />
            <img
              className={desaturateBrand("american-express", this.brand, true)}
              src={getBrandLogo("american-express")}
            />
          </div>

          <FormGroup field={ccNumber}>
            <div className="form-label">{T("Card Number")}</div>
            <div className="form-input-stripe" ref={this.acquireCCNumberRef} />
          </FormGroup>

          <div className="form-grid-row">
            <div className="form-grid-col-6">
              <FormGroup field={name}>
                <div className="form-label">{T("Name on Card")}</div>
                <input type="text" className="form-input" {...name.text} />
              </FormGroup>
            </div>
            <div className="form-grid-col-3 form-grid-col-m-6">
              <FormGroup field={ccExpiry}>
                <div className="form-label">{T("Expiry Date")}</div>
                <div className="form-input-stripe" ref={this.acquireCCExpiryRef} />
              </FormGroup>
            </div>
            <div className="form-grid-col-3 form-grid-col-m-6">
              <FormGroup field={ccCVC}>
                <div className="form-label">{T("Security Code")}</div>
                <div className="form-input-stripe" ref={this.acquireCCCVVRef} />
              </FormGroup>
            </div>
          </div>

          <FormGroup field={address}>
            <div className="form-label">{T("Street Address")}</div>
            <input type="text" className="form-input" {...address.text} />
          </FormGroup>
          <FormGroup field={country}>
            <div className="form-label">{T("Country")}</div>
            <CountrySelect {...country.raw} />
          </FormGroup>
          <div className="form-grid-row">
            <div className="form-grid-col-6">
              <FormGroup field={province}>
                <div className="form-label">{T("State")}</div>
                <ProvinceSelect {...province.raw} countryCode={country.value} />
              </FormGroup>
            </div>
            <div className="form-grid-col-6">
              <FormGroup field={zip}>
                <div className="form-label">{T("Postal Code")}</div>
                <input type="text" className="form-input" {...zip.text} />
              </FormGroup>
            </div>
          </div>
          <FormGroup field={city}>
            <div className="form-label">{T("City")}</div>
            <input type="text" className="form-input" {...city.text} />
          </FormGroup>

          <div className="form-group">
            <Checkbox label={T("Save as default credit card")} {...as_default.checkbox} />
          </div>
          <div className="account-page-form__buttons">
            <button
              type="button"
              className="banner-button banner-button--small banner-button--no-min-width"
              onClick={onCancel}
              disabled={pending}
            >
              {T("Cancel")}
            </button>
            <button className="banner-button banner-button--small" disabled={pending || !this.props.form.isValid}>
              {pending ? <Spinner small /> : T("Save")}
            </button>
          </div>
        </form>
      </>
    );
  }
}

const CardForm = formalizeExternal<typeof cardFormDefinition, CardFormProps>(CardFormClass);

//==========================================================================================
//==========================================================================================
//==========================================================================================

interface CardItemProps {
  item: CustomerPaymentSourceFragment;
  customer: StorefrontCustomerFragment;
  onDelete: (id: string) => void;
}

class CardItem extends React.Component<CardItemProps> {
  handleDeleteClick = () => {
    const { item, onDelete } = this.props;
    onDelete(item.id);
  };

  render() {
    const { item, customer } = this.props;
    const nbsp = <span>&nbsp;</span>;
    const tag = item.id === customer.default_payment_source_id ? T("Default") : <span>&nbsp;</span>;
    const brand = getBrandForStripeBrand(item.brand);
    return (
      <div className="cards__item-container">
        <img className="cards__logo" src={getBrandLogo(brand)} />
        <div className="cards__item">
          <div className="cards__number">
            {T("{brand} ending with {last4}", { brand: getNiceBrandName(brand), last4: item.last4 })}
          </div>
          <div className="cards__expiry">{T("Expiry: {mm} / {yy}", { mm: item.exp_month, yy: item.exp_year })}</div>
          <div className="cards__address">
            {item.name || nbsp}
            <br />
            {item.address_line1 || nbsp}
            <br />
            {(item.address_state_info ? item.address_state_info.name : "") || nbsp}
            <br />
            {(item.address_country_info ? item.address_country_info.name : "") || nbsp}
          </div>
          <div className="cards__tag">{tag}</div>
          <div className="cards__delete">
            <a className="default-link" onClick={this.handleDeleteClick}>
              {T("Delete")}
            </a>
          </div>
        </div>
      </div>
    );
  }
}

//==========================================================================================
//==========================================================================================
//==========================================================================================

interface PaymentMethodsPageProps {
  data: DataType;
  siteData: RouteData.SiteData;
  env: Environment;
  goTo: (path: string) => void;
}

@observer
class PaymentMethodsPage extends React.Component<PaymentMethodsPageProps> {
  @observable pending = false;
  @observable formVisible = false;

  private element: any;
  handleElement = (e: any) => {
    this.element = e;
  };

  handleAddCardClick = () => {
    this.formVisible = true;
  };

  handleCardFormCancel = () => {
    this.formVisible = false;
  };

  getCustomer() {
    return respItems(this.props.data)?.storefrontCustomers?.[0] || undefined;
  }

  getPaymentSources() {
    return respItems(this.props.data)?.storefrontPaymentSources || undefined;
  }

  getDefaultBillingAddress() {
    const customer = this.getCustomer();
    if (!customer) return undefined;

    const addresses = customer.addresses;
    return (
      find(addresses, (addr) => addr.id === customer.default_billing_address_id) ||
      find(addresses, (addr) => addr.id === customer.default_shipping_address_id) ||
      find(addresses, () => true)
    );
  }

  handleAPIError(e?: RequestResponseError) {
    if (!e) return;
    if (e.kind !== "error") return;
    const err = e.error.apiError;
    if (!err) return;
    if (err.code === codes.StripeAPICallFailure && err.meta && err.meta.code) {
      const serr = stripeErrorForCode(err.meta.code);
      if (serr) {
        this.formData.propagateErrors([serr]);
      }
    }
  }

  handlePaymentSourceDelete = preventConcurrency(async (id: string) => {
    const {
      siteData: { config, shop },
      goTo,
    } = this.props;
    this.pending = true;
    const resp = await request(deletePaymentSource)(
      {
        shopID: shop.id,
        id,
      },
      { url: `${config.host}/storefront.json`, authToken: authJWT(globalAuthState.auth) }
    );
    if (resp.kind === "data") {
      goTo(paths.accountPaymentMethods);
    } else {
      console.error(prettyPrintRequestResponseError(resp));
    }
    this.pending = false;
  });

  handleCardFormSubmit = preventConcurrency(async (data: typeof cardFormDefinition.data) => {
    try {
      const {
        siteData: { config, shop },
        goTo,
        env,
      } = this.props;
      this.pending = true;
      const billingDetails = creditCardFormToPaymentMethodDetails(data);
      const payment_method = await getStripe(env).createPaymentMethod({
        type: "card",
        card: this.element,
        billing_details: billingDetails,
      });
      if (payment_method.error) {
        console.error("failed creating a payment method");
        return;
      }
      const respPS = await request(createPaymentSource)(
        {
          shopID: shop.id,
          data: { payment_method: payment_method.paymentMethod.id },
        },
        { url: `${config.host}/storefront.json`, authToken: authJWT(globalAuthState.auth) }
      );
      if (respPS.kind !== "data") {
        this.handleAPIError(respPS);
        console.error(prettyPrintRequestResponseError(respPS));
        return;
      }

      if (data.as_default) {
        const customer = this.getCustomer();
        if (!customer) return;
        const resp = await request(modifyCustomer)(
          {
            shopID: shop.id,
            id: customer.id,
            data: {
              default_payment_source_id: respPS.data[0].id,
            },
          },
          { url: `${config.host}/storefront.json`, authToken: authJWT(globalAuthState.auth) }
        );
        if (resp.kind !== "data") {
          this.handleAPIError(resp);
          console.error(prettyPrintRequestResponseError(resp));
          return;
        }
      }

      goTo(paths.accountPaymentMethods);
      this.formVisible = false;
    } catch (err) {
      extractStripeErrorAndPropagateToFormOrRethrow(err, this.formData);
    } finally {
      this.pending = false;
    }
  });

  formData = new FormData("Card", cardFormDefinition, this.handleCardFormSubmit);

  renderZero() {
    return !this.formVisible ? (
      <div className="account-page-centered margin-block">{T("You haven’t saved any credit cards yet.")}</div>
    ) : null;
  }

  renderNotAvailable() {
    return <div className="account-page-centered margin-block">{T("This feature is not available.")}</div>;
  }

  render() {
    const customer = this.getCustomer();
    if (!customer) return null;

    const { env } = this.props;
    if (!env.stripeCardTokenization) return this.renderNotAvailable();

    const paymentSources = this.getPaymentSources();
    const daddr = this.getDefaultBillingAddress();
    return (
      <div className="account-layout">
        <AccountSidebar active="payment-methods" />
        <div className="account-layout__content">
          <div className="account-page-header">
            <AccountBackArrow />
            <div className="account-page-header__title">{T("Saved Cards")}</div>
          </div>
          {!paymentSources || paymentSources.length === 0 ? (
            this.renderZero()
          ) : (
            <div className="margin-block">
              <div className="cards">
                {map(paymentSources, (ps) => (
                  <CardItem key={ps.id} item={ps} customer={customer} onDelete={this.handlePaymentSourceDelete} />
                ))}
              </div>
            </div>
          )}
          {!this.formVisible ? (
            <div className="account-page-centered">
              <a className="default-link" onClick={this.handleAddCardClick}>
                {T("Add Credit Card")}
              </a>
            </div>
          ) : (
            <CardForm
              env={env}
              formData={this.formData}
              onElement={this.handleElement}
              onCancel={this.handleCardFormCancel}
              pending={this.pending}
              initialValue={
                daddr
                  ? {
                      name: daddr.name,
                      address: daddr.address1,
                      city: daddr.city,
                      zip: daddr.zip,
                      country: daddr.country?.code,
                      province: daddr.province?.code,
                    }
                  : undefined
              }
            />
          )}
        </div>
      </div>
    );
  }
}

export default (_props: RouteComponentProps) => {
  const siteData = useSiteData();
  const env = environmentFromSiteData(siteData);
  const location = usePrefetchLocation();
  const forceUpdate = useForceUpdate();
  const data = globalDataCache.accountPaymentMethodsCache.get(
    env,
    globalAuthState.auth,
    location.pathname,
    location.key,
    forceUpdate
  );
  if (!data) return null;
  return (
    <div className="page-with-menu">
      <PaymentMethodsPage data={data} env={env} siteData={siteData} goTo={navigate} />
      <div className="page-with-menu__content page-with-menu__content--no-padding page-with-menu__content--no-max-width">
        <FooterMenu />
        <Footer />
      </div>
    </div>
  );
};
