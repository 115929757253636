import React from "react";
import { AsYouType } from "libphonenumber-js/max";
import { Rifm } from "rifm";
import { observable } from "mobx";
import { observer } from "mobx-react";

let asYouTypePromise: Promise<typeof AsYouType> | undefined;

interface PhoneInputRenderProps {
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

interface PhoneInputProps {
  value: string;
  onChange: (v: string) => void;
  countryCode?: string;
  renderInput: (props: PhoneInputRenderProps) => JSX.Element;
}

@observer
export class PhoneInput extends React.Component<PhoneInputProps> {
  @observable.ref asYouType: typeof AsYouType | undefined;

  componentDidMount() {
    if (!asYouTypePromise) {
      asYouTypePromise = import("libphonenumber-js/max").then((m) => m.AsYouType);
    }
    asYouTypePromise.then((ctor) => {
      this.asYouType = ctor;
    });
  }

  formatPhone = (v: string) => {
    const { countryCode } = this.props;
    const { asYouType } = this;
    return asYouType ? new asYouType(countryCode as any).input(v) : v;
  };

  render() {
    const { value, onChange, renderInput } = this.props;
    const v = this.formatPhone(value);
    return (
      <Rifm accept={/[\d+]+/g} value={v} onChange={onChange} format={this.formatPhone}>
        {renderInput}
      </Rifm>
    );
  }
}
