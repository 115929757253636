import React from "react";
import newPassword from "gql/operations/NewPasswordMutation";
import resetPassword from "gql/operations/ResetPasswordMutation";
import verifyCode from "gql/operations/VerifyCodeMutation";
import { ErrorCode } from "@lana-commerce/core/generated/codes";
import { FObject, FString, FormData, valid } from "@trunkery/internal/lib/formaline";
import { Footer } from "components/Footer";
import { FooterMenu } from "components/FooterMenu";
import { FormGroup } from "components/FormGroup";
import { FormInnerProps, formalizeExternal } from "@trunkery/internal/lib/formaline/react";
import { Link, navigate } from "gatsby";
import { RouteComponentProps } from "@reach/router";
import { RouteData } from "@trunkery/internal/lib/vatureTypes";
import { Spinner } from "components/Spinner";
import { globalAuthState } from "utils/globalAuthState";
import { mapFieldErrors } from "utils/mapFieldErrors";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { paths } from "utils/paths";
import { prettyPrintRequestResponseError, request } from "@lana-commerce/core/request";
import { useSiteData } from "utils/useSiteData";

import { T } from "./PasswordReset.tlocale";

const passwordChangeFormDefinition = FObject({
  password: FString(valid.nonEmpty),
});

const passwordResetFormDefinition = FObject({
  email: FString(valid.nonEmpty, valid.email, valid.maxLength250),
});

const verifyCodeFormDefinition = FObject({
  code: FString(valid.nonEmpty),
});

interface PasswordChangeFormProps {
  pending: boolean;
}

const PasswordChangeForm = formalizeExternal<typeof passwordChangeFormDefinition, PasswordChangeFormProps>(
  class extends React.Component<FormInnerProps<typeof passwordChangeFormDefinition, PasswordChangeFormProps>> {
    render() {
      const {
        form: { password },
        pending,
        handleSubmit,
      } = this.props;
      return (
        <form onSubmit={handleSubmit}>
          <div className="centered-form">
            <div className="centered-form__content">
              <FormGroup field={password}>
                <div className="form-label">{T("New Password")}</div>
                <input type="password" className="form-input" {...password.text} />
              </FormGroup>
              <div className="form-group">
                <button
                  disabled={pending || !this.props.form.isValid}
                  className="banner-button banner-button--full-width"
                >
                  {pending ? <Spinner /> : T("Reset Password")}
                </button>
              </div>
            </div>
          </div>
        </form>
      );
    }
  }
);

interface VerifyCodeFormProps {
  pending: boolean;
}

const VerifyCodeForm = formalizeExternal<typeof verifyCodeFormDefinition, VerifyCodeFormProps>(
  class extends React.Component<FormInnerProps<typeof verifyCodeFormDefinition, VerifyCodeFormProps>> {
    render() {
      const {
        form: { code },
        pending,
        handleSubmit,
      } = this.props;
      return (
        <form onSubmit={handleSubmit}>
          <div className="centered-form">
            <div className="centered-form__content">
              <FormGroup field={code}>
                <div className="form-label">{T("Verification Code")}</div>
                <input type="text" className="form-input" {...code.text} />
              </FormGroup>
              <div className="form-group">
                <button
                  disabled={pending || !this.props.form.isValid}
                  className="banner-button banner-button--full-width"
                >
                  {pending ? <Spinner /> : T("Verify Code")}
                </button>
              </div>
            </div>
          </div>
        </form>
      );
    }
  }
);

interface PasswordResetFormProps {
  pending: boolean;
}

const PasswordResetForm = formalizeExternal<typeof passwordResetFormDefinition, PasswordResetFormProps>(
  class extends React.Component<FormInnerProps<typeof passwordResetFormDefinition, PasswordResetFormProps>> {
    render() {
      const {
        form: { email },
        pending,
        handleSubmit,
      } = this.props;
      return (
        <form onSubmit={handleSubmit}>
          <div className="centered-form">
            <div className="centered-form__content">
              <FormGroup field={email}>
                <div className="form-label">{T("Email")}</div>
                <input type="email" className="form-input" {...email.text} />
              </FormGroup>
              <div className="form-group">
                <button
                  disabled={pending || !this.props.form.isValid}
                  className="banner-button banner-button--full-width"
                >
                  {pending ? <Spinner /> : T("Reset Password")}
                </button>
              </div>
              <div className="tacenter default-font">
                {T("Don’t have an account?")}{" "}
                <Link className="default-link" to={paths.accountSignup}>
                  {T("Sign Up")}
                </Link>
              </div>
            </div>
          </div>
        </form>
      );
    }
  }
);

interface PasswordResetPageProps {
  siteData: RouteData.SiteData;
}

@observer
class PasswordResetPage extends React.Component<PasswordResetPageProps> {
  @observable pending = false;
  @observable step = 0;

  email = ""; // step 1
  code = ""; // step 2

  handleSubmitPasswordReset = async (data: typeof passwordResetFormDefinition.data) => {
    const {
      siteData: { shop, config },
    } = this.props;
    this.pending = true;
    const resp = await request(resetPassword)({ shopID: shop.id, data }, { url: `${config.host}/storefront.json` });
    if (resp.kind === "data") {
      this.email = data.email;
      this.step = 1;
    } else {
      if (resp.kind === "error" && resp.error.apiError?.code === ErrorCode.TryAgainLater) {
        this.passwordResetFormData.propagateErrors([
          {
            problem: undefined,
            field: "email",
            message: T("Please wait one minute before attempting to change your password again"),
          },
        ]);
      } else if (!mapFieldErrors(resp, this.passwordResetFormData.propagateErrors)) {
        console.error(prettyPrintRequestResponseError(resp));
      }
    }
    this.pending = false;
  };

  handleSubmitVerifyCode = async (data: typeof verifyCodeFormDefinition.data) => {
    const {
      siteData: { shop, config },
    } = this.props;
    this.pending = true;
    const resp = await request(verifyCode)(
      {
        shopID: shop.id,
        data: {
          email: this.email,
          code: data.code,
        },
      },
      { url: `${config.host}/storefront.json` }
    );
    if (resp.kind === "data") {
      this.code = data.code;
      this.step = 2;
    } else {
      if (resp.kind === "error" && resp.error.apiError?.code === ErrorCode.NotFound) {
        this.verifyCodeFormData.propagateErrors([
          {
            problem: undefined,
            field: "code",
            message: T("The code you entered is invalid, expired or you've already made 5 failed attempts"),
          },
        ]);
      } else if (!mapFieldErrors(resp, this.verifyCodeFormData.propagateErrors)) {
        console.error(prettyPrintRequestResponseError(resp));
      }
    }
    this.pending = false;
  };

  handleSubmitPasswordChange = async (data: typeof passwordChangeFormDefinition.data) => {
    const {
      siteData: { config, shop },
    } = this.props;
    this.pending = true;
    const resp = await request(newPassword)(
      {
        shopID: shop.id,
        data: {
          email: this.email,
          code: this.code,
          password: data.password,
        },
      },
      { url: `${config.host}/storefront.json` }
    );
    if (resp.kind === "data") {
      globalAuthState.logout();
      navigate(paths.accountSignin);
    } else {
      if (!mapFieldErrors(resp, this.passwordChangeFormData.propagateErrors)) {
        console.error(prettyPrintRequestResponseError(resp));
      }
    }
    this.pending = false;
  };

  passwordResetFormData = new FormData("PasswordReset", passwordResetFormDefinition, this.handleSubmitPasswordReset);
  passwordChangeFormData = new FormData(
    "PasswordChange",
    passwordChangeFormDefinition,
    this.handleSubmitPasswordChange
  );
  verifyCodeFormData = new FormData("VerifyCode", verifyCodeFormDefinition, this.handleSubmitVerifyCode);

  render() {
    return (
      <>
        <div className="signup-header">{T("Reset Password")}</div>
        {this.step === 0 ? (
          <PasswordResetForm pending={this.pending} formData={this.passwordResetFormData} />
        ) : this.step === 1 ? (
          <VerifyCodeForm pending={this.pending} formData={this.verifyCodeFormData} />
        ) : this.step === 2 ? (
          <PasswordChangeForm pending={this.pending} formData={this.passwordChangeFormData} />
        ) : (
          <div>SUCCESS</div>
        )}
      </>
    );
  }
}

export default (_props: RouteComponentProps) => {
  const siteData = useSiteData();
  return (
    <div className="page-with-menu">
      <PasswordResetPage siteData={siteData} />
      <div className="page-with-menu__content page-with-menu__content--no-padding page-with-menu__content--no-max-width">
        <FooterMenu />
        <Footer />
      </div>
    </div>
  );
};
