import React from "react";

interface CheckboxProps {
  checked: boolean;
  onChange: () => void;
  label?: string;
}

export class Checkbox extends React.Component<CheckboxProps> {
  handleKeyUp = (e: React.KeyboardEvent<any>) => {
    if (e.keyCode === 32) {
      // space
      this.props.onChange();
    }
  };

  handleKeyDown = (e: React.KeyboardEvent<any>) => {
    if (e.keyCode === 32) {
      // space
      e.preventDefault();
    }
  };

  render() {
    const { checked, onChange, label } = this.props;
    return (
      <div
        className="checkbox"
        tabIndex={0}
        onClick={onChange}
        onKeyUp={this.handleKeyUp}
        onKeyDown={this.handleKeyDown}
      >
        <div className="checkbox__box">{checked ? <div className="checkbox__tick" /> : null}</div>
        {label ? <div className="checkbox__label">{label}</div> : null}
      </div>
    );
  }
}
