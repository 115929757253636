import React from "react";
import { Link } from "gatsby";
import { paths } from "utils/paths";

export const AccountBackArrow = () => {
  return (
    <Link className="account-page-header__arrow" to={paths.account}>
      <img src={require("images/arrow-left-1.svg").default} />
    </Link>
  );
};
