import React, { useContext, useEffect, useState } from "react";
import { Router, WindowLocation, useLocation } from "@reach/router";
import { globalAuthState } from "utils/globalAuthState";
import { globalDataCache } from "utils/globalDataCache";
import { useEnvironment } from "@trunkery/internal/lib/environmentContext";

export const PrefetchLocationContext = React.createContext<WindowLocation | undefined>(undefined);

export function usePrefetchLocation() {
  const value = useContext(PrefetchLocationContext);
  if (!value) throw new Error("usePrefetchLocation() must be used within PrefetchLocationContext hierarchy");
  return value;
}

interface PrefetchRouterImplProps {
  location: WindowLocation;
}

class PrefetchRenderImpl extends React.Component<PrefetchRouterImplProps> {
  shouldComponentUpdate(nextProps: Readonly<PrefetchRouterImplProps>) {
    // DOESN'T WORK, REACT BUG? https://github.com/facebook/react/issues/11508
    return this.props.location.key !== nextProps.location.key;
  }
  render() {
    const { location, children } = this.props;
    return (
      <PrefetchLocationContext.Provider value={location}>
        <Router location={location}>{children}</Router>
      </PrefetchLocationContext.Provider>
    );
  }
}

interface PrefetchRouterProps {
  children: React.ReactNode;
}

export const PrefetchRouter = (props: PrefetchRouterProps) => {
  const location = useLocation();
  const env = useEnvironment();
  const [currentLocation, setCurrentLocation] = useState<WindowLocation>(location);
  useEffect(() => {
    if (currentLocation.key !== location.key) {
      // transition requested
      globalDataCache.prefetch(location.pathname, globalAuthState.auth, env, location.key).then(() => {
        setCurrentLocation(location);
      });
    }
  }, [location.key]);
  return <PrefetchRenderImpl location={currentLocation} children={props.children} />;
};
