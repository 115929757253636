import { CategoryBrief } from "@trunkery/internal/lib/vatureTypes";
import { Dict } from "@lana-commerce/core/commonTypes";
import { MenuItemFragment } from "@trunkery/internal/lib/vature-gen/types";
import { categoryLink } from "utils/categoryLink";

export function menuItemLink(item: MenuItemFragment, categoryBriefs: Dict<CategoryBrief>) {
  switch (item.type) {
    case "category":
      return item.category ? categoryLink(item.category.id, categoryBriefs) : "";
    case "content_block":
      return item.content_block ? `/pages/${item.content_block.handle}` : "";
    case "empty":
    case "meta":
      return "/";
    case "link":
      return item.link || "";
    case "product":
      return item.product ? `/products/${item.product.handle}` : "";
    case "search":
      return ""; // TODO
  }
}
