import { StorefrontOrderShortFragment } from "gql/types";

import { T } from "./niceFulfillmentText.tlocale";

export function niceFulfillmentText(f: StorefrontOrderShortFragment["fulfillment_status"]) {
  switch (f) {
    case "fulfilled":
      return T("Fulfilled");
    case "partial":
      return T("Partial");
    case "unfulfilled":
      return T("Unfulfilled");
  }
}
