import find from "lodash/find";
import { FullAddressFragment } from "gql/types";

interface Customerer {
  addresses: Array<FullAddressFragment | null> | null;
  default_billing_address_id: string;
  default_shipping_address_id: string;
}

export function customerDefaultAddress(customer: Customerer | undefined | null) {
  if (!customer) return undefined;
  const ca = customer.addresses;
  if (!ca) return undefined;
  if (ca.length === 0) return undefined;
  return (
    find(ca, (a) => !!a && a.id === customer.default_billing_address_id) ||
    find(ca, (a) => !!a && a.id === customer.default_shipping_address_id) ||
    ca[0] ||
    undefined
  );
}
