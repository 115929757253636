import React from "react";
import { Footer } from "components/Footer";
import { FooterMenu } from "components/FooterMenu";
import { RouteComponentProps } from "@reach/router";
import { environmentFromSiteData } from "utils/environmentFromSiteData";
import { globalAuthState } from "utils/globalAuthState";
import { globalDataCache } from "utils/globalDataCache";
import { useForceUpdate } from "utils/useForceUpdate";
import { usePrefetchLocation } from "components/PrefetchRouter";
import { useSiteData } from "utils/useSiteData";

import { T } from "./Confirm.tlocale";

interface ConfirmPageProps {
  success: boolean;
}

class ConfirmPage extends React.Component<ConfirmPageProps> {
  render() {
    const { success } = this.props;
    return (
      <>
        <div className="signup-header">{T("Confirm Email")}</div>
        <div className="centered-form">
          <div className="centered-form__content">
            <div className="tacenter">
              {success
                ? T("Your email was successfully confirmed")
                : T("Failed to confirm your email, try reloading the page")}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default (_props: RouteComponentProps) => {
  const siteData = useSiteData();
  const env = environmentFromSiteData(siteData);
  const location = usePrefetchLocation();
  const forceUpdate = useForceUpdate();
  const data = globalDataCache.accountConfirmCache.get(
    env,
    globalAuthState.auth,
    location.pathname,
    location.key,
    forceUpdate
  );
  if (!data) return null;
  return (
    <div className="page-with-menu">
      <ConfirmPage success={data} />
      <div className="page-with-menu__content page-with-menu__content--no-padding page-with-menu__content--no-max-width">
        <FooterMenu />
        <Footer />
      </div>
    </div>
  );
};
