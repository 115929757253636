import React from "react";
import compact from "lodash/compact";
import find from "lodash/find";
import map from "lodash/map";
import { Link } from "gatsby";
import { menuItemLink } from "utils/menuItemLink";
import { useSiteData } from "utils/useSiteData";

import { T } from "./Footer.tlocale";

export const Footer = () => {
  const { shop, menus, categoryBriefs } = useSiteData();
  const menuItems = find(menus, (m) => m.handle === "page-footer")?.items || [];
  const year = new Date().getFullYear();
  const languages = compact(shop.languages);
  return (
    <div className="page-footer">
      <div className="page-footer__copyright">
        {T("Vature Pty Ltd")}
        <br />
        {T("© {date} Vature. All rights reserved.", { date: `${year}` })}
      </div>
      <div className="page-footer__links">
        {map(menuItems, (item) => (
          <span key={item.id} className="page-footer__link">
            <Link to={menuItemLink(item, categoryBriefs)}>{item.name}</Link>
          </span>
        ))}
        {languages.length > 0 ? (
          <span className="page-footer__link">
            <select id="the-lang-select">
              {shop.language ? (
                <option value={shop.language.code}>{T("{name} [{code}] (Default)", shop.language)}</option>
              ) : null}
              {map(languages, (lang) => (
                <option key={lang.code} value={lang.code}>
                  {T("{name} [{code}]", lang)}
                </option>
              ))}
            </select>
          </span>
        ) : null}
        <span className="page-footer__link">
          {T("Powered by {trunkery}", { trunkery: <a href="https://trunkery.com">Trunkery</a> })}
        </span>
      </div>
    </div>
  );
};
