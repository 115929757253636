import { StorefrontOrderFragment } from "gql/types";

import { T } from "./niceFinancialText.tlocale";

export function niceFinancialText(f: StorefrontOrderFragment["financial_status"]) {
  switch (f) {
    case "authorized":
      return T("Authorized");
    case "paid":
      return T("Paid");
    case "partially_paid":
      return T("Partially Paid");
    case "partially_refunded":
      return T("Partially Refunded");
    case "refunded":
      return T("Refunded");
    case "unpaid":
      return T("Unpaid");
    case "voided":
      return T("Voided");
  }
}
