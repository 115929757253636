import React from "react";
import { Footer } from "components/Footer";
import { FooterMenu } from "components/FooterMenu";
import { GetCustomerQuery, GetOrdersPageQuery } from "gql/types";
import { Link } from "gatsby";
import { OrdersPage } from "components/OrdersPage";
import { RequestResponseMulti } from "@lana-commerce/core/request";
import { RouteComponentProps } from "@reach/router";
import { SignInLink } from "components/SignInLink";
import { environmentFromSiteData } from "utils/environmentFromSiteData";
import { globalAuthState } from "utils/globalAuthState";
import { globalDataCache } from "utils/globalDataCache";
import { paths } from "utils/paths";
import { respItems } from "utils/respItems";
import { useForceUpdate } from "utils/useForceUpdate";
import { usePrefetchLocation } from "components/PrefetchRouter";
import { useSiteData } from "utils/useSiteData";

import { T } from "./Index.tlocale";

type DataType = RequestResponseMulti<GetCustomerQuery & GetOrdersPageQuery>;

interface AccountMenuProps {
  data: DataType | undefined;
}

const AccountMenu = (props: AccountMenuProps) => {
  const siteData = useSiteData();
  const env = environmentFromSiteData(siteData);
  const signOutClick = (e: React.MouseEvent<any>) => {
    e.preventDefault();
    globalAuthState.logout();
  };

  const { data } = props;
  const name = data ? respItems(data)?.storefrontCustomers?.[0]?.name || "" : "";
  return (
    <>
      <div className="mobile-account-home mobile-only">
        {name ? <div className="mobile-account-home__welcome">{`Hello, ${name}`}</div> : null}
        {globalAuthState.auth.kind === "not_authorized" ? (
          <>
            <SignInLink className="mobile-account-home__item">{T("Sign In")}</SignInLink>
            <Link className="mobile-account-home__item" to={paths.accountSignup}>
              {T("Sign Up")}
            </Link>
          </>
        ) : (
          <>
            <Link className="mobile-account-home__item" to={paths.accountOrders}>
              {T("Orders")}
            </Link>
            <Link className="mobile-account-home__item" to={paths.accountAddresses}>
              {T("Address Book")}
            </Link>
            {env.stripeCardTokenization ? (
              <Link className="mobile-account-home__item" to={paths.accountPaymentMethods}>
                {T("Saved Cards")}
              </Link>
            ) : null}
            <Link className="mobile-account-home__item" to={paths.accountStoreCredit}>
              {T("Store Credit")}
            </Link>
            <Link className="mobile-account-home__item" to={paths.accountProfile}>
              {T("Profile")}
            </Link>
            <a className="mobile-account-home__item" onClick={signOutClick}>
              {T("Sign Out")}
            </a>
          </>
        )}
      </div>
      {data ? <OrdersPage desktopOnly data={data} /> : null}
    </>
  );
};

export default (_props: RouteComponentProps) => {
  const siteData = useSiteData();
  const env = environmentFromSiteData(siteData);
  const location = usePrefetchLocation();
  const forceUpdate = useForceUpdate();
  const data = globalDataCache.accountCache.get(
    env,
    globalAuthState.auth,
    location.pathname,
    location.key,
    forceUpdate
  );
  return (
    <div className="page-with-menu">
      <AccountMenu data={data} />
      <div className="page-with-menu__content page-with-menu__content--no-padding page-with-menu__content--no-max-width">
        <FooterMenu />
        <Footer />
      </div>
    </div>
  );
};
