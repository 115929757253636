import React from "react";
import map from "lodash/map";
import { ProvinceSelectGeneric } from "components/ProvinceSelectGeneric";
import { useSiteData } from "utils/useSiteData";

interface ProvinceSelectProps {
  countryCode: string;
  value: string;
  onChange: (value: string) => void;
}

export const ProvinceSelect = (props: ProvinceSelectProps) => {
  const siteData = useSiteData();
  return (
    <ProvinceSelectGeneric
      siteData={siteData}
      countryCode={props.countryCode}
      renderComponent={(provinces) => (
        <div className="form-select-wrapper">
          <select
            className="form-select"
            value={props.value}
            onChange={(e) => {
              props.onChange(e.target.value);
            }}
          >
            <option value="" />
            {map(provinces, (p) => (
              <option key={p.code} value={p.code}>
                {p.name}
              </option>
            ))}
          </select>
        </div>
      )}
    />
  );
};
