import * as React from "react";
import filter from "lodash/filter";
import map from "lodash/map";
import modifyCustomer from "gql/operations/ModifyCustomerMutation";
import omit from "lodash/omit";
import { AccountBackArrow } from "components/AccountBackArrow";
import { AccountSidebar } from "components/AccountSidebar";
import {
  AddressCreate,
  FullAddressFragment,
  GetCountriesQuery,
  GetCustomerQuery,
  GetGeoIPQuery,
  InfoCountryFragment,
  StorefrontCustomerFragment,
} from "gql/types";
import { Checkbox } from "components/Checkbox";
import { CountrySelect } from "components/CountrySelect";
import { FBoolean, FObject, FString, FormData, valid } from "@trunkery/internal/lib/formaline";
import { Footer } from "components/Footer";
import { FooterMenu } from "components/FooterMenu";
import { FormGroup } from "components/FormGroup";
import { FormInnerProps, formalizeExternal } from "@trunkery/internal/lib/formaline/react";
import { ProvinceSelect } from "components/ProvinceSelect";
import { RequestResponseMulti, prettyPrintRequestResponseError, request } from "@lana-commerce/core/request";
import { RouteComponentProps } from "@reach/router";
import { RouteData } from "@trunkery/internal/lib/vatureTypes";
import { Spinner } from "components/Spinner";
import { authJWT } from "@trunkery/internal/lib/auth";
import { environmentFromSiteData } from "utils/environmentFromSiteData";
import { globalAuthState } from "utils/globalAuthState";
import { globalDataCache } from "utils/globalDataCache";
import { navigate } from "gatsby";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { paths } from "utils/paths";
import { preventConcurrency } from "utils/preventConcurrency";
import { respItems } from "utils/respItems";
import { useForceUpdate } from "utils/useForceUpdate";
import { usePrefetchLocation } from "components/PrefetchRouter";
import { useSiteData } from "utils/useSiteData";

import { T } from "./Addresses.tlocale";

type DataType = RequestResponseMulti<GetCustomerQuery & GetGeoIPQuery & GetCountriesQuery>;

//==========================================================================================
//==========================================================================================
//==========================================================================================

const addressFormDefinition = FObject({
  name: FString(valid.nonEmpty, valid.maxLength160),
  address1: FString(valid.nonEmpty, valid.maxLength190),
  address2: FString(valid.maxLength190),
  city: FString(valid.nonEmpty, valid.maxLength80),
  zip: FString(valid.nonEmpty, valid.maxLength32),
  country: FString(valid.nonEmpty),
  country_name: FString(),
  province: FString(valid.nonEmpty),
  province_name: FString(),
  default_shipping: FBoolean(),
  default_billing: FBoolean(),
});

interface AddressFormProps {
  countries: InfoCountryFragment[] | undefined;
  pending: boolean;
  onCancel: () => void;
}

const AddressForm = formalizeExternal<typeof addressFormDefinition, AddressFormProps>(
  class extends React.Component<FormInnerProps<typeof addressFormDefinition, AddressFormProps>> {
    render() {
      const {
        form: { name, address1, address2, city, zip, country, province, default_shipping, default_billing },
        pending,
        onCancel,
        handleSubmit,
        countries,
      } = this.props;
      return (
        <form onSubmit={handleSubmit} className="account-page-form">
          <FormGroup field={name}>
            <div className="form-label">{T("Name")}</div>
            <input type="text" className="form-input" {...name.text} />
          </FormGroup>
          <FormGroup field={address1}>
            <div className="form-label">{T("Street Address 1")}</div>
            <input type="text" className="form-input" {...address1.text} />
          </FormGroup>
          <FormGroup field={address2}>
            <div className="form-label">{T("Street Address 2")}</div>
            <input type="text" className="form-input" {...address2.text} />
          </FormGroup>
          <FormGroup field={country}>
            <div className="form-label">{T("Country")}</div>
            <CountrySelect {...country.raw} countries={countries} />
          </FormGroup>
          <div className="form-grid-row">
            <div className="form-grid-col-6">
              <FormGroup field={province}>
                <div className="form-label">{T("State")}</div>
                <ProvinceSelect {...province.raw} countryCode={country.value} />
              </FormGroup>
            </div>
            <div className="form-grid-col-6">
              <FormGroup field={zip}>
                <div className="form-label">{T("Postal Code")}</div>
                <input type="text" className="form-input" {...zip.text} />
              </FormGroup>
            </div>
          </div>
          <FormGroup field={city}>
            <div className="form-label">{T("City")}</div>
            <input type="text" className="form-input" {...city.text} />
          </FormGroup>
          <div className="form-group">
            <Checkbox label={T("Default shipping address")} {...default_shipping.checkbox} />
          </div>
          <div className="form-group">
            <Checkbox label={T("Default billing address")} {...default_billing.checkbox} />
          </div>
          <div className="account-page-form__buttons">
            <button
              type="button"
              className="banner-button banner-button--small banner-button--no-min-width"
              onClick={onCancel}
              disabled={pending}
            >
              {T("Cancel")}
            </button>
            <button className="banner-button banner-button--small" disabled={pending || !this.props.form.isValid}>
              {pending ? <Spinner small /> : T("Save")}
            </button>
          </div>
        </form>
      );
    }
  }
);

//==========================================================================================
//==========================================================================================
//==========================================================================================

interface AddressItemProps {
  address: FullAddressFragment;
  idx: number;
  customer: StorefrontCustomerFragment;
  onDelete: (idx: number) => void;
}

class AddressItem extends React.Component<AddressItemProps> {
  handleDeleteClick = () => {
    const { idx, onDelete } = this.props;
    onDelete(idx);
  };

  render() {
    const { address: a, customer: c } = this.props;
    const tag =
      a.id === c.default_billing_address_id && a.id === c.default_shipping_address_id ? (
        T("Default")
      ) : a.id === c.default_billing_address_id ? (
        T("Default Billing")
      ) : a.id === c.default_shipping_address_id ? (
        T("Default Shipping")
      ) : (
        <span>&nbsp;</span>
      );

    return (
      <div className="addresses__item-container">
        <div className="addresses__item">
          <div className="addresses__address">
            {a.name}
            {a.company ? (
              <>
                <br />
                {a.company}
              </>
            ) : null}
            <br />
            {a.address1}
            {a.address2 ? (
              <>
                <br />
                {a.address2}
              </>
            ) : null}
            <br />
            {a.city} {a.province ? a.province.code : ""} {a.zip}
            <br />
            {a.country ? a.country.name : ""}
            {a.phone ? (
              <>
                <br />
                {a.phone}
              </>
            ) : null}
          </div>
          <div className="addresses__tag">{tag}</div>
          <div className="addresses__delete">
            <a className="default-link" onClick={this.handleDeleteClick}>
              {T("Delete")}
            </a>
          </div>
        </div>
      </div>
    );
  }
}

//==========================================================================================
//==========================================================================================
//==========================================================================================

function addressToAPI(addr: FullAddressFragment): AddressCreate {
  return {
    ...omit(addr, "country", "province", "latitude", "longitude"),
    country: addr.country ? addr.country.code : "",
    province: addr.province ? addr.province.code : "",
  };
}

//==========================================================================================
//==========================================================================================
//==========================================================================================

interface AddressesPageProps {
  data: DataType;
  siteData: RouteData.SiteData;
  goTo: (path: string) => void;
}

@observer
class AddressesPage extends React.Component<AddressesPageProps> {
  @observable pending = false;
  @observable formVisible = false;

  handleAddAddressClick = () => {
    this.formVisible = true;
  };

  handleAddressFormCancel = () => {
    this.formVisible = false;
  };

  handleSubmit = preventConcurrency(async (data: typeof addressFormDefinition.data) => {
    const customer = this.getCustomer();
    if (!customer) return;

    const addrData = {
      ...omit(data, ["default_billing", "default_shipping", "country_name", "province_name"]),
      phone: "",
      company: "",
    };

    const {
      siteData: { shop, config },
      goTo,
    } = this.props;
    this.pending = true;
    const addresses = [...map(customer.addresses, addressToAPI), addrData];
    const m = {
      addresses,
      default_billing_address_id: data.default_billing ? "first" : undefined,
      default_shipping_address_id: data.default_shipping ? "first" : undefined,
    };
    const cresp = await request(modifyCustomer)(
      {
        shopID: shop.id,
        id: customer.id,
        data: m,
      },
      { url: `${config.host}/storefront.json`, authToken: authJWT(globalAuthState.auth) }
    );
    if (cresp.kind === "data") {
      goTo(paths.accountAddresses);
      this.formVisible = false;
    } else {
      console.error(prettyPrintRequestResponseError(cresp));
    }
    this.pending = false;
  });

  formData = new FormData("Address", addressFormDefinition, this.handleSubmit);

  handleAddressDelete = preventConcurrency(async (idx: number) => {
    const customer = this.getCustomer();
    if (!customer) return;

    const addr = customer.addresses[idx];
    const dbaid = customer.default_billing_address_id;
    const dsaid = customer.default_shipping_address_id;
    const ndbaid = dbaid === addr.id ? "" : dbaid;
    const ndsaid = dsaid === addr.id ? "" : dsaid;

    const addresses = map(
      filter(customer.addresses, (_addr, lidx) => lidx !== idx),
      addressToAPI
    );
    const {
      siteData: { shop, config },
      goTo,
    } = this.props;
    this.pending = true;
    const resp = await request(modifyCustomer)(
      {
        shopID: shop.id,
        id: customer.id,
        data: {
          addresses,
          default_billing_address_id: ndbaid,
          default_shipping_address_id: ndsaid,
        },
      },
      { url: `${config.host}/storefront.json`, authToken: authJWT(globalAuthState.auth) }
    );
    if (resp.kind === "data") {
      goTo(paths.accountAddresses);
    } else {
      console.error(prettyPrintRequestResponseError(resp));
    }
    this.pending = false;
  });

  getCustomer() {
    return respItems(this.props.data)?.storefrontCustomers?.[0] || undefined;
  }

  getGeoIP() {
    return respItems(this.props.data)?.analyticsGeoip || undefined;
  }

  getCountries() {
    return respItems(this.props.data)?.countries || undefined;
  }

  getInitialValue() {
    const geoip = this.getGeoIP();
    return geoip
      ? {
          country: geoip.country ? geoip.country.code : "",
          country_name: geoip.country ? geoip.country.name : "",
          province: geoip.province ? geoip.province.code : "",
          province_name: geoip.province ? geoip.province.name : "",
          city: geoip.geo_city,
        }
      : undefined;
  }

  renderZero() {
    return !this.formVisible ? (
      <div className="account-page-centered margin-block">{T("You haven't saved any addresses yet.")}</div>
    ) : null;
  }

  render() {
    const customer = this.getCustomer();
    if (!customer) return null;

    const countries = this.getCountries();
    const addresses = customer.addresses;
    return (
      <div className="account-layout">
        <AccountSidebar active="addresses" />
        <div className="account-layout__content">
          <div className="account-page-header">
            <AccountBackArrow />
            <div className="account-page-header__title">{T("Address Book")}</div>
          </div>
          {addresses.length === 0 ? (
            this.renderZero()
          ) : (
            <div className="margin-block">
              <div className="addresses">
                {map(addresses, (addr, idx) => (
                  <AddressItem
                    key={idx}
                    idx={idx}
                    address={addr}
                    customer={customer}
                    onDelete={this.handleAddressDelete}
                  />
                ))}
              </div>
            </div>
          )}
          {!this.formVisible ? (
            <div className="account-page-centered">
              <a className="default-link" onClick={this.handleAddAddressClick}>
                {T("Add Address")}
              </a>
            </div>
          ) : (
            <AddressForm
              countries={countries}
              onCancel={this.handleAddressFormCancel}
              formData={this.formData}
              pending={this.pending}
              initialValue={this.getInitialValue()}
            />
          )}
        </div>
      </div>
    );
  }
}

export default (_props: RouteComponentProps) => {
  const siteData = useSiteData();
  const env = environmentFromSiteData(siteData);
  const location = usePrefetchLocation();
  const forceUpdate = useForceUpdate();
  const data = globalDataCache.accountAddressesCache.get(
    env,
    globalAuthState.auth,
    location.pathname,
    location.key,
    forceUpdate
  );
  if (!data) return null;
  return (
    <div className="page-with-menu">
      <AddressesPage data={data} siteData={siteData} goTo={navigate} />
      <div className="page-with-menu__content page-with-menu__content--no-padding page-with-menu__content--no-max-width">
        <FooterMenu />
        <Footer />
      </div>
    </div>
  );
};
