import React from "react";
import isString from "lodash/isString";
import loginReq from "gql/operations/LoginMutation";
import queryString from "query-string";
import { Footer } from "components/Footer";
import { FooterMenu } from "components/FooterMenu";
import { FormData } from "@trunkery/internal/lib/formaline";
import { RouteComponentProps, useLocation } from "@reach/router";
import { RouteData } from "@trunkery/internal/lib/vatureTypes";
import { SignInForm, signInFormDefinition } from "components/SignInForm";
import { globalAuthState } from "utils/globalAuthState";
import { mapFieldErrors } from "utils/mapFieldErrors";
import { navigate } from "gatsby";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { paths } from "utils/paths";
import { prettyPrintRequestResponseError, request } from "@lana-commerce/core/request";
import { useSiteData } from "utils/useSiteData";

import { T } from "./Signin.tlocale";

interface SignInPageProps {
  siteData: RouteData.SiteData;
  goTo: (path: string) => void;
  returnTo?: string;
}

@observer
class SignInPage extends React.Component<SignInPageProps> {
  @observable pending = false;
  @observable error = false;

  handleSubmit = async (data: typeof signInFormDefinition.data) => {
    const {
      siteData: { shop, config },
      goTo,
      returnTo,
    } = this.props;
    this.pending = true;
    const resp = await request(loginReq)({ shopID: shop.id, data }, { url: `${config.host}/storefront.json` });
    if (resp.kind === "data") {
      const token = resp.data.token;
      if (token) globalAuthState.login(token, data.email);
      if (returnTo) {
        goTo(returnTo);
      } else {
        goTo(paths.account);
      }
    } else {
      if (!mapFieldErrors(resp, this.formData.propagateErrors)) {
        this.error = true;
        console.error(prettyPrintRequestResponseError(resp));
      }
    }
    this.pending = false;
  };

  formData = new FormData("SignIn", signInFormDefinition, this.handleSubmit);
  render() {
    return (
      <>
        <div className="signup-header">{T("Sign In")}</div>
        <SignInForm pending={this.pending} formData={this.formData} error={this.error} />
      </>
    );
  }
}

export default (_props: RouteComponentProps) => {
  const siteData = useSiteData();
  const location = useLocation();
  const q = queryString.parse(location.search);
  const returnTo = isString(q.returnTo) ? q.returnTo : undefined;
  return (
    <div className="page-with-menu">
      <SignInPage siteData={siteData} goTo={navigate} returnTo={returnTo} />
      <div className="page-with-menu__content page-with-menu__content--no-padding page-with-menu__content--no-max-width">
        <FooterMenu />
        <Footer />
      </div>
    </div>
  );
};
